/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useRef } from 'react'

import { BoxGeometry, EdgesGeometry, Matrix3, Matrix4, Mesh, Quaternion } from 'three'

import { EDITOR_FRAME_COLORS } from 'config/styles'

import { Cuboid } from 'interfaces/interfaces'

const CommentCuboidFrame: FC<{
  cuboid?: Cuboid
}> = ({ cuboid }) => {
  const lineRef = useRef<Mesh>(null)
  const groupRef = useRef<Mesh>(null)

  if (!cuboid) {
    return null
  }

  const geometry = new EdgesGeometry(new BoxGeometry(cuboid.extent[0], cuboid.extent[1], cuboid.extent[2]))
  const quaternion = new Quaternion().setFromRotationMatrix(
    new Matrix4().setFromMatrix3(new Matrix3().fromArray(cuboid.rotation))
  )

  return (
    <>
      {/* ref must be any */}
      <group ref={groupRef as any} position={cuboid.center} quaternion={quaternion}>
        <group ref={lineRef as any}>
          <lineSegments geometry={geometry}>
            <lineBasicMaterial color={EDITOR_FRAME_COLORS.comments} />
          </lineSegments>
        </group>
      </group>
    </>
  )
}

export default CommentCuboidFrame
