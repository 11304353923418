import { FC, useContext, useEffect, useRef, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  VStack,
  useOutsideClick,
} from '@chakra-ui/react'
import { setUser as setSentryUser } from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { useCookies } from 'react-cookie'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  CloseIcon,
  LogoDark,
  LogoLight,
  MenuIcon,
  SettingsIcon,
  TranslateIcon,
} from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { ServiceStatusContext } from 'contexts/ServiceStatus'
import { UserContext } from 'contexts/Users'

import { COOKIE_EXPIRE, LANGUAGES, SELECTED_ORGANIZATION_COOKIE_NAME, USER_TYPES, Z_INDEX } from 'config/constants'
import { CONTAINER_MAX_WIDTH, NAV_BAR_HEIGHT, NOTIFICATION_STYLES } from 'config/styles'

import { updateLanguage } from 'services/Users'

import { NotificationMessage, ServiceStatus } from '../../interfaces/interfaces'
import InviteUserModal from './InviteUserModal'

// isInverted: used for Landing page
const Navbar: FC<{ isInverted?: boolean }> = ({ isInverted }) => {
  const { user, logout } = useAuth0()
  const { serviceStatus } = useContext(ServiceStatusContext)
  const { subscriptionId, userType, organizations, getAccessToken } = useContext(UserContext)
  const { handleError } = useContext(GlobalModalContext)
  const [cookies, setCookie] = useCookies([SELECTED_ORGANIZATION_COOKIE_NAME])

  const currentOrganization = organizations.find(
    (organization) => organization.organization_id === cookies[SELECTED_ORGANIZATION_COOKIE_NAME]
  )
  const hasStripeSubscription = !!currentOrganization?.stripe_subscription_id
  const isOrganizationOwner = !!user?.sub && currentOrganization?.owner_users.includes(user.sub)

  const { t, i18n } = useTranslation(['navbar'])

  const navLinksRef = useRef<HTMLDivElement>(null)
  const navbarButtonRef = useRef<HTMLButtonElement>(null)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [isTabletMenuOpen, setIsTabletMenuOpen] = useState(false)
  const [isNotificationBarVisible, setIsNotificationBarVisible] = useState(true)

  // Update the selected organization in the cookie
  useEffect(() => {
    // only if there is cookie and organizations are loaded
    if (cookies && organizations.length) {
      const selectedOrganization = organizations.find(
        (org) => org.organization_id === cookies[SELECTED_ORGANIZATION_COOKIE_NAME]
      )
      // in case the selected organization is not found in the list of organizations
      const defaultOrganizationId = organizations.length ? organizations[0].organization_id : ''

      // update the cookie expiration date
      setCookie(SELECTED_ORGANIZATION_COOKIE_NAME, selectedOrganization?.organization_id || defaultOrganizationId, {
        expires: COOKIE_EXPIRE,
      })
    }
  }, [cookies, organizations, setCookie])

  useOutsideClick({
    ref: navLinksRef,
    handler: () => {
      if (isTabletMenuOpen) {
        setIsTabletMenuOpen(false)
      }
    },
  })

  const changeLanguage = async (lang: string) => {
    // variable used only for tracking
    const langOriginal = i18n.language

    await i18n.changeLanguage(lang)

    // track with mixpanel
    mixpanel.track('Update language preference', {
      'Language Preference (old)': langOriginal,
      'Language Preference (new)': lang,
    })

    const token = user && (await getAccessToken())
    if (!token || !user?.sub) {
      return
    }

    await updateLanguage(token, user.sub, lang, handleError)
  }

  const languageOptions = (
    <Menu autoSelect={false}>
      <MenuButton
        p={2}
        transition="all 0.3s"
        _focus={{ boxShadow: 'none' }}
        _hover={{ background: 'blackAlpha.200' }}
        aria-label="language"
        borderRadius={8}
      >
        <TranslateIcon size={24} />
      </MenuButton>
      <MenuList color="secondary.600">
        <MenuItem
          onClick={() => changeLanguage(LANGUAGES.JA)}
          backgroundColor={!i18n.language || i18n.language === LANGUAGES.JA ? 'primary.50' : 'transparent'}
        >
          日本語
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage(LANGUAGES.EN)}
          backgroundColor={i18n.language === LANGUAGES.EN ? 'primary.50' : 'transparent'}
        >
          English
        </MenuItem>
      </MenuList>
    </Menu>
  )

  const userActions = () => {
    if (!user) {
      return (
        <HStack spacing={6}>
          {!!isInverted && (
            <Button variant="ghost" as={RouterLink} to="/dashboard">
              {t('login', { ns: 'navbar' })}
            </Button>
          )}
          {!isInverted && (
            <Button colorScheme="primary" as={RouterLink} to="/dashboard">
              {t('login', { ns: 'navbar' })}
            </Button>
          )}
          <Button colorScheme="primary" as={RouterLink} to="/register">
            {t('register', { ns: 'navbar' })}
          </Button>
          {languageOptions}
        </HStack>
      )
    }

    return (
      <HStack spacing={6}>
        <>
          <Button
            variant={isInverted ? 'outlineStaticInvert' : 'outlineStatic'}
            size="sm"
            onClick={() => setIsInviteModalOpen(true)}
          >
            {t('invite_user', { ns: 'navbar' })}
          </Button>
          <InviteUserModal isOpen={isInviteModalOpen} onConfirm={() => setIsInviteModalOpen(false)} />
        </>
        {languageOptions}
        <Menu autoSelect={false}>
          <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }} aria-label="user-profile">
            <HStack>
              <Avatar size="sm" src={user?.picture} />
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems="flex-start"
                spacing={-1}
                ml="2"
                whiteSpace="nowrap"
              >
                <Text fontSize="sm" fontWeight="semibold">
                  {user?.nickname}
                </Text>
                <Text fontSize="xs" fontWeight="semibold">
                  {organizations.find((org) => org.organization_id === cookies[SELECTED_ORGANIZATION_COOKIE_NAME])
                    ?.organization_name || ''}
                </Text>
              </VStack>
              <Box display={{ base: 'none', md: 'flex' }}>
                <ChevronDownIcon />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList color="secondary.600">
            <MenuItem
              onClick={() => {
                // mixpanel tracking
                mixpanel.track('Logout')
                logout({ returnTo: window.location.origin })

                // reset user registration in mixpanel/sentry
                mixpanel.reset()
                setSentryUser(null)
              }}
            >
              {t('logout', { ns: 'navbar' })}
            </MenuItem>
            <MenuDivider />
            <MenuGroup title={t('switch_organization', { ns: 'navbar' })}>
              {organizations.map((org) => (
                <MenuItem
                  key={org.organization_id}
                  onClick={() => {
                    setCookie(SELECTED_ORGANIZATION_COOKIE_NAME, org.organization_id, {
                      expires: COOKIE_EXPIRE,
                    })
                    // track with mixpanel
                    mixpanel.track('Switch organization', {
                      'Organization ID': org.organization_id,
                      'Number of organizations': organizations.length,
                    })
                  }}
                  backgroundColor={
                    org.organization_id === cookies[SELECTED_ORGANIZATION_COOKIE_NAME] ? 'primary.50' : 'transparent'
                  }
                >
                  <Text flex={1}>{org.organization_name}</Text>
                  <IconButton
                    aria-label="organization-management"
                    icon={<SettingsIcon />}
                    size="sm"
                    variant="ghost"
                    as={RouterLink}
                    to={`/organizations/${org.organization_id}`}
                    my={-1}
                    _hover={{ background: 'primary.100' }}
                  />
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
    )
  }

  const navLinkClass = isTablet ? 'nav-link-tablet' : 'nav-link'
  const getNavLinksClass = () => {
    if (!isTablet) {
      return ''
    }
    if (isTabletMenuOpen) {
      return 'nav-links-tablet active'
    }
    return 'nav-links-tablet'
  }

  const urlHelpCenter = t('user_manual.url', { ns: 'navbar' })
  const navLinks = () => (
    <Stack
      spacing={0}
      px={isTablet ? 0 : 4}
      position={isTablet ? 'absolute' : 'static'}
      direction={isTablet ? 'column' : 'row'}
      height={isTablet ? '100vh' : 'auto'}
      backgroundColor={isInverted ? 'white' : 'primary.500'}
      zIndex={Z_INDEX.navbar.nav_links}
      top={`${(navbarButtonRef.current?.offsetTop || 0) + (isInverted ? 60 : 54)}px`}
      left={0}
      shadow={isTablet ? '2xl' : 'none'}
      className={getNavLinksClass()}
    >
      {!!user && (
        <Link as={RouterNavLink} className={navLinkClass} to="/dashboard" variant="button">
          {t('project', { ns: 'navbar' })}
        </Link>
      )}
      {!!user && userType === USER_TYPES.ADMIN && (
        <Link as={RouterNavLink} className={navLinkClass} to="/user-list" variant="button">
          {t('user_list', { ns: 'navbar' })}
        </Link>
      )}
      {(!!subscriptionId || (hasStripeSubscription && isOrganizationOwner)) && (
        <Link as={RouterNavLink} className={navLinkClass} to="/usage" variant="button">
          {t('usage_info', { ns: 'navbar' })}
        </Link>
      )}
      {!!user && (
        <Link
          className={navLinkClass}
          href={urlHelpCenter}
          isExternal
          variant="button"
          onClick={() => {
            // track with mixpanel
            // As it is external link, a new window will pop up.
            // So, we can track with track() without using track_links().
            // Ref: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-website-links
            mixpanel.track('Move to modely help center', {
              'Moved from': 'navbar',
              'Target URL': urlHelpCenter,
            })
          }}
        >
          {t('user_manual.text', { ns: 'navbar' })}
        </Link>
      )}
      <Link
        className={navLinkClass}
        href="https://share.hsforms.com/1XdVwIncBTtqo6el1_YJU9Qddydr"
        isExternal
        variant="button"
      >
        {t('inquiry', { ns: 'navbar' })}
      </Link>
    </Stack>
  )

  const { language = LANGUAGES.JA as keyof ServiceStatus } = i18n
  type Languages = typeof LANGUAGES[keyof typeof LANGUAGES]
  const notificationOnSelectedLanguage = serviceStatus?.notification[language as Languages] as NotificationMessage
  const notification = () =>
    serviceStatus?.notification.enabled && isNotificationBarVisible ? (
      <Box w="100%" {...NOTIFICATION_STYLES[serviceStatus.notification.type].box}>
        <Container maxW={CONTAINER_MAX_WIDTH} py={2}>
          <Text {...NOTIFICATION_STYLES[serviceStatus.notification.type].text}>
            {notificationOnSelectedLanguage?.message} &nbsp;
            <Button
              as={Link}
              href={notificationOnSelectedLanguage?.url}
              {...NOTIFICATION_STYLES[serviceStatus.notification.type].button}
            >
              {notificationOnSelectedLanguage?.url_text}
            </Button>
          </Text>
          <Button
            {...NOTIFICATION_STYLES[serviceStatus.notification.type].button}
            position="absolute"
            top={2}
            right={2}
            color="white"
            onClick={() => {
              setIsNotificationBarVisible(false)
            }}
          >
            <CloseIcon />
          </Button>
        </Container>
      </Box>
    ) : null

  return (
    <VStack w="100%" spacing={0}>
      {notification()}
      <Box
        backgroundColor={isInverted ? 'white' : 'primary.500'}
        boxShadow={isInverted ? '' : 'md'}
        color={isInverted ? '' : 'white'}
        w="100%"
      >
        <Container maxW={CONTAINER_MAX_WIDTH} ref={navLinksRef}>
          <Flex align="center" w="100%" minH={`${NAV_BAR_HEIGHT}px`} py={isInverted ? 12 : 0}>
            {isTablet && (
              <IconButton
                aria-label="nav-menu"
                variant="primary"
                icon={isTabletMenuOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                size="lg"
                onClick={() => {
                  setIsTabletMenuOpen(!isTabletMenuOpen)
                }}
                ref={navbarButtonRef}
              />
            )}
            <Link as={RouterLink} to="/">
              {isInverted ? <LogoDark /> : <LogoLight />}
            </Link>

            {navLinks()}

            <Spacer />

            {userActions()}
          </Flex>
        </Container>
      </Box>
    </VStack>
  )
}

// Handle typing for props that is not required
Navbar.defaultProps = {
  isInverted: undefined,
}

export default Navbar
