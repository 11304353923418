import { FC, useContext } from 'react'

import { Box, HStack, Stack, StackDivider } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import {
  ToolbarAxis1Icon,
  ToolbarAxis2Icon,
  ToolbarAxis3Icon,
  ToolbarCylinderOnArcIcon,
  ToolbarCylinderOnAxisIcon,
  ToolbarRotateIcon,
  ToolbarScaleIcon,
  ToolbarTranslateIcon,
} from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_CUBOID_DIRECTIONS, EDITOR_SHAPES_SITUATIONS, EDITOR_SUB_TOOLS, EDITOR_TOOLS } from 'config/constants'

import { CuboidDirection } from 'interfaces/interfaces'

import { setCuboidDirection } from '../store/cuboid'
import AssistantBar from './AssistantBar'
import SubToolbarButton from './components/SubToolbarButton'
import SubToolbarToggle from './components/SubToolbarToggle'

const SubToolbar: FC<{ moveToPrefixedPosition: (position: string) => void }> = ({ moveToPrefixedPosition }) => {
  const { t } = useTranslation(['projects'])
  const { project_id } = useParams<{ project_id: string }>()

  const { changeAutoDetectSituation, autoDetectSituation, selectedTool } = useContext(EditorContext)
  const { cuboidDirection } = useSelector((state: RootState) => state.cuboid)
  const dispatch = useAppDispatch()

  return (
    <Stack
      left="50%"
      transform="translateX(-50%)"
      overflow="hidden"
      position="absolute"
      spacing={4}
      top={1}
      h={isTablet ? 32 : 8}
      direction={isTablet ? 'column' : 'row'}
      alignItems="center"
    >
      <HStack spacing={4}>
        <HStack
          backgroundColor="gray.800"
          borderRadius="md"
          overflow="hidden"
          divider={<StackDivider borderColor="whiteAlpha.200" />}
          spacing={0}
        >
          <SubToolbarButton
            icon={<ToolbarTranslateIcon width="50%" height="50%" />}
            label={t('main_canvas.sub_toolbar.translate', { ns: 'projects' })}
            toolType={EDITOR_SUB_TOOLS.CUBOID.TRANSLATE}
            dataTestId="detection-tool-cuboid-translate-button"
          />
          <SubToolbarButton
            icon={<ToolbarScaleIcon width="50%" height="50%" />}
            label={t('main_canvas.sub_toolbar.extent', { ns: 'projects' })}
            toolType={EDITOR_SUB_TOOLS.CUBOID.SCALE}
            dataTestId="detection-tool-cuboid-scale-button"
          />
          <SubToolbarButton
            icon={<ToolbarRotateIcon width="50%" height="50%" />}
            label={t('main_canvas.sub_toolbar.rotate', { ns: 'projects' })}
            toolType={EDITOR_SUB_TOOLS.CUBOID.ROTATE}
            dataTestId="detection-tool-cuboid-rotate-button"
          />
        </HStack>
        {(selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID || selectedTool === EDITOR_TOOLS.TORUS_CUBOID) && (
          <Box backgroundColor="gray.800" borderRadius="md" overflow="hidden">
            <SubToolbarToggle
              value={cuboidDirection}
              label={t('main_canvas.sub_toolbar.alignment_axis', { ns: 'projects' })}
              values={[
                {
                  icon: <ToolbarAxis1Icon width="40%" height="40%" />,
                  value: EDITOR_CUBOID_DIRECTIONS.X,
                  color: 'red.900',
                },
                {
                  icon: <ToolbarAxis2Icon width="40%" height="40%" />,
                  value: EDITOR_CUBOID_DIRECTIONS.Y,
                  color: 'green.900',
                },
                {
                  icon: <ToolbarAxis3Icon width="40%" height="40%" />,
                  value: EDITOR_CUBOID_DIRECTIONS.Z,
                  color: 'blue.900',
                },
              ]}
              onChange={(direction: string) => {
                dispatch(setCuboidDirection(direction as CuboidDirection))

                // track with mixpanel
                mixpanel.track('Change axis of cuboid detection tool', {
                  'Inspection area ID': project_id,
                  Tool: selectedTool,
                  'Axis (new)': direction,
                })
              }}
            />
          </Box>
        )}
        {selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID && (
          <Box backgroundColor="gray.800" borderRadius="md" overflow="hidden">
            <SubToolbarToggle
              value={autoDetectSituation}
              label={t('main_canvas.sub_toolbar.configuration', { ns: 'projects' })}
              values={[
                {
                  icon: <ToolbarCylinderOnAxisIcon width="50%" height="50%" />,
                  value: EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS,
                  color: 'yellow.900',
                },
                {
                  icon: <ToolbarCylinderOnArcIcon width="50%" height="50%" />,
                  value: EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC,
                  color: 'yellow.900',
                },
              ]}
              onChange={(situation: string) => {
                changeAutoDetectSituation(situation)

                // track with mixpanel
                mixpanel.track('Change alignment situation in shape detection tool (cuboid)', {
                  'Inspection area ID': project_id,
                  Tool: selectedTool,
                  'Situation (new)': situation,
                })
              }}
            />
          </Box>
        )}
      </HStack>
      <AssistantBar move={moveToPrefixedPosition} fixedPosition={false} />
    </Stack>
  )
}

export default SubToolbar
