import { FC, useContext } from 'react'

import { Box, Spacer } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import {
  EDITOR_COLLAPSE_TYPES,
  EDITOR_CUBOID_KEY,
  EDITOR_MEASURE_KEYS,
  EDITOR_SHAPE_KEYS,
  EDITOR_TOOLS,
} from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import DiameterPanel from './DiameterPanel'

const PropertyPanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const { baseDiameter } = useSelector((state: RootState) => state.editor)
  const { cuboidAnchor } = useSelector((state: RootState) => state.cuboid)
  const { selectedPoint, anchors, selectedTool } = useContext(EditorContext)

  if (
    !selectedPoint ||
    selectedPoint.shapeKey === EDITOR_MEASURE_KEYS.DISTANCE ||
    selectedPoint.shapeKey === EDITOR_MEASURE_KEYS.SPACER_ANNOTATION ||
    selectedTool === EDITOR_TOOLS.COMMENT_CUBOID ||
    selectedTool === EDITOR_TOOLS.PLANE ||
    selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID
  ) {
    return null
  }

  const getDiameterValue = () => {
    if (selectedPoint.shapeKey === EDITOR_CUBOID_KEY) {
      return cuboidAnchor?.diameter || baseDiameter
    }
    if (selectedPoint.shapeKey === EDITOR_SHAPE_KEYS.CYLINDERS || selectedPoint.shapeKey === EDITOR_SHAPE_KEYS.TORI) {
      return anchors[selectedPoint.shapeKey][selectedPoint.anchorIndex].diameter
    }
    return baseDiameter
  }
  return (
    <Box backgroundColor="gray.800" borderBottomLeftRadius="md" borderTopLeftRadius="md" w="100%">
      <CollapsePanel
        title={t('main_canvas.panels.specify_diameter', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.diameter}
      >
        {selectedPoint.shapeKey !== EDITOR_SHAPE_KEYS.PLANES && (
          <>
            <Spacer h={INFO_PANEL_PADDING * 1.5} />
            <DiameterPanel diameter={getDiameterValue()} />
          </>
        )}
        <Spacer h={INFO_PANEL_PADDING} />
      </CollapsePanel>
    </Box>
  )
}

export default PropertyPanel
