import { FC } from 'react'

import { HStack } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useAppDispatch } from 'store/app'
import { Vector3 } from 'three'

import { Z_INDEX_RANGE } from 'config/constants'

import { Comment } from 'interfaces/interfaces'

import { resetCuboidStates } from '../../store/cuboid'
import CommentIcon from './CommentIcon'

const UnplacedCommentIcons: FC<{
  comments: Comment[]
  disabled: boolean
  isMovingComment: boolean
  selectedComment?: Comment
  setSelectedComment: (comment?: Comment) => void
  onCancelMove: () => void
  onConfirmMove: () => void
}> = ({ comments, disabled, isMovingComment, selectedComment, setSelectedComment, onCancelMove, onConfirmMove }) => {
  const dispatch = useAppDispatch()

  return (
    <Html
      position={new Vector3()}
      style={{
        opacity: disabled ? '0.6' : '1',
        position: 'relative',
      }}
      zIndexRange={Z_INDEX_RANGE.comment_unplaced}
      className="unprintable"
    >
      <HStack
        flexWrap="wrap"
        // icon size = 24px
        // padding left and right = 4px
        // size is just for alignment in 2D HTML element, not relate to 3D space
        maxW={`${Math.ceil(Math.sqrt(comments.length)) * (24 + 4 * 2)}px`}
        spacing={0}
        transform="translateX(-50%) translateY(-50%)"
      >
        {comments.map((comment) => (
          <CommentIcon
            unplaced
            key={comment.thread_id}
            disabled={disabled || (isMovingComment && selectedComment?.thread_id !== comment.thread_id)}
            visible={isMovingComment || selectedComment !== comment}
            onClick={() => {
              setSelectedComment(comment)
              dispatch(resetCuboidStates())
            }}
            isMoving={isMovingComment && comment.thread_id === selectedComment?.thread_id}
            onCancelMove={onCancelMove}
            onConfirmMove={onConfirmMove}
            isHoldingPlace={selectedComment?.thread_id === comment.thread_id && isMovingComment}
          />
        ))}
      </HStack>
    </Html>
  )
}

export default UnplacedCommentIcons
