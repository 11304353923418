import React, { FC, ReactNode } from 'react'

import { Box, Flex, HStack, IconButton, Spacer, Square, Tooltip } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'

import {
  AlertFillIcon,
  BookmarkPlusIcon,
  CollapseIcon,
  DeleteIcon,
  ExpandIcon,
  HideIcon,
  InputEditorEditIcon as PencilIcon,
  ShowIcon,
} from 'assets/icons'

import { LAYER_ITEM_STYLES } from 'config/styles'

const IconActionButton: FC<{
  ariaLabel: string
  icon: React.ReactElement
  selected?: boolean
  onClick: () => void
}> = ({ ariaLabel, icon, selected, onClick }) => {
  const fontSize = isTablet ? 'md' : 'sm'
  const iconSize = isTablet ? 'sm' : 'xs'

  return (
    <IconButton
      aria-label={ariaLabel}
      fontSize={fontSize}
      icon={icon}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      size={iconSize}
      variant="toolbarIcon"
      color={selected ? 'white' : 'inherit'}
    />
  )
}

const LayerItem: FC<{
  label: ReactNode
  collapsed?: boolean
  collapsible?: boolean
  invisible?: boolean
  selected?: boolean
  disabled?: boolean
  errorMessage?: string
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  onGroup?: () => void
  onEdit?: () => void
  updateVisibility?: (invisible: boolean) => void
  updateExpansion?: (expanded: boolean) => void
  deleteLayer?: () => void
  childLevel?: number
  isHighlighting?: boolean
  tagColor?: string
  groupColor?: string
}> = ({
  label,
  collapsible,
  collapsed,
  invisible,
  selected,
  disabled,
  errorMessage,
  onClick,
  onMouseOver,
  onMouseOut,
  onGroup,
  onEdit,
  updateVisibility,
  updateExpansion,
  deleteLayer,
  childLevel,
  isHighlighting,
  tagColor,
  groupColor,
}) => {
  const tagColorSize = isTablet ? '16px' : '10px'

  return (
    <Flex
      position="relative"
      className={`${onClick && !disabled ? 'editor-clickable-item' : ''}${
        isHighlighting ? ' animation-blinking-primary' : ''
      }`}
      backgroundColor={selected ? 'whiteAlpha.400' : 'transparent'}
      color={selected ? 'white' : 'inherit'}
      w="100%"
      alignItems="center"
      {...LAYER_ITEM_STYLES}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation()
          onClick()
        }
      }}
      onMouseOver={() => {
        if (onMouseOver) onMouseOver()
      }}
      onMouseOut={() => {
        if (onMouseOut) onMouseOut()
      }}
    >
      {groupColor && (
        <Box
          backgroundColor={groupColor}
          w="1px"
          display="inline-block"
          mr="2px"
          position="absolute"
          left={childLevel ? 3 * childLevel : 0}
          top="-1px"
          bottom="-1px"
        />
      )}
      <Box pl={childLevel ? 3 * childLevel : 0} whiteSpace="nowrap" isTruncated>
        {tagColor && (
          <Box
            backgroundColor={tagColor}
            w={tagColorSize}
            h={tagColorSize}
            display="inline-block"
            borderRadius="50%"
            mr="2px"
            data-testid="tag-color"
          />
        )}
        {label}
      </Box>
      <Spacer />
      <HStack spacing={0}>
        {/* 24px */}
        {!!onEdit && <IconActionButton icon={<PencilIcon />} ariaLabel="edit" onClick={onEdit} selected={selected} />}
        {!!onGroup && (
          <IconActionButton icon={<BookmarkPlusIcon />} ariaLabel="group" onClick={onGroup} selected={selected} />
        )}
        {updateVisibility && (
          <IconActionButton
            icon={invisible ? <HideIcon /> : <ShowIcon />}
            ariaLabel="show-hide"
            onClick={() => {
              updateVisibility(!invisible)
            }}
            selected={selected}
          />
        )}
        {deleteLayer && !disabled && !collapsible && (
          <IconActionButton icon={<DeleteIcon />} ariaLabel="delete" onClick={deleteLayer} selected={selected} />
        )}
        {(!deleteLayer || disabled) && !collapsible && <Square size={6} />}
        {!deleteLayer && !disabled && collapsible && (
          <IconActionButton
            icon={collapsed ? <ExpandIcon /> : <CollapseIcon />}
            ariaLabel="collapse"
            onClick={() => {
              if (updateExpansion) updateExpansion(!collapsed)
            }}
            selected={selected}
          />
        )}
      </HStack>
      {!!errorMessage && (
        <Tooltip hasArrow placement="right" label={errorMessage} p={2} fontSize="xs" fontWeight="normal">
          <Box position="absolute" left={2} top={2} fontSize="80%">
            <AlertFillIcon color="orange" />
          </Box>
        </Tooltip>
      )}
    </Flex>
  )
}

export default LayerItem
