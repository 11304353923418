/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable dot-notation */
import { FC, useContext, useEffect, useRef, useState } from 'react'

import { TransformControls } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { BoxGeometry, EdgesGeometry, Matrix3, Matrix4, Mesh, Object3D, Quaternion, Vector3 } from 'three'
import { TransformControlsGizmo, TransformControls as TransformControlsImpl } from 'three-stdlib'

import { EditorContext } from 'contexts/Editor'

import {
  DIAMETERS,
  EDITOR_REQUIRED_ANCHORS,
  EDITOR_SHAPES_SITUATIONS,
  EDITOR_TOOLS,
  EDITOR_TOOL_KEYS,
} from 'config/constants'
import { EDITOR_FRAME_COLORS } from 'config/styles'

import { Cuboid, CuboidAnchor } from 'interfaces/interfaces'

import { generateDummyFactors, getCuboidFromPoints, pointsToVector3s } from 'services/Points'
import { millimeterToMeter } from 'services/Util'

import { setCuboid } from '../../store/cuboid'
import DummyCylinderMesh from '../DummyCylinderMesh'
import DummyTorusMesh from '../DummyTorusMesh'

// Within Transform control, except for gizmos (which actually meshes) with X, Y or Z name,
// hide and disable other gizmos/meshes that used for translation, rotation and scaling
const removeGizmo = (mesh: Mesh | Object3D) => {
  if (['XYZX', 'XYZY', 'XYZZ', 'XYZ', 'XY', 'YZ', 'XZ', 'E'].includes(mesh.name)) {
    mesh.visible = false
    mesh.layers.disableAll()
  }
}

const CuboidFrame: FC<{
  cuboidAnchor?: CuboidAnchor
  cuboid?: Cuboid
  baseDiameter: number
  maxSize: number
}> = ({ cuboidAnchor, cuboid, baseDiameter, maxSize }) => {
  const { selectedSubTool, selectedTool, autoDetectSituation } = useContext(EditorContext)
  const { cuboid: editorCuboid, cuboidDirection } = useSelector((state: RootState) => state.cuboid)
  const dispatch = useAppDispatch()
  const lineRef = useRef<Mesh>(null)
  const groupRef = useRef<Mesh>(null)
  const transformRef = useRef<TransformControlsImpl>(null)
  const [cuboidInfo, setCuboidInfo] = useState<{
    width: number
    depth: number
    height: number
    position: Vector3
    quaternion: Quaternion
    edgesGeometry?: EdgesGeometry<BoxGeometry>
    rotationMatrix?: Matrix4
  }>()
  const prevCuboidAnchorRef = useRef<CuboidAnchor>()

  const convertedDiameter = millimeterToMeter(baseDiameter || DIAMETERS.D10)
  const updateCuboid = () => {
    if (lineRef.current && transformRef.current && cuboidInfo) {
      const minScaleX = convertedDiameter / (cuboidInfo?.width || 1)
      const minScaleY = convertedDiameter / (cuboidInfo?.height || 1)
      const minScaleZ = convertedDiameter / (cuboidInfo?.depth || 1)
      const maxScaleX = maxSize / (cuboidInfo?.width || 1)
      const maxScaleY = maxSize / (cuboidInfo?.height || 1)
      const maxScaleZ = maxSize / (cuboidInfo?.depth || 1)

      const { scale } = lineRef.current
      let { width, depth, height } = cuboidInfo
      width *= scale.x
      height *= scale.y
      depth *= scale.z

      let needReset = false
      if (minScaleX > scale.x) {
        needReset = true
        lineRef.current.scale.x = minScaleX
        width = convertedDiameter
      }
      if (minScaleY > scale.y) {
        needReset = true
        lineRef.current.scale.y = minScaleY
        height = convertedDiameter
      }
      if (minScaleZ > scale.z) {
        needReset = true
        lineRef.current.scale.z = minScaleZ
        depth = convertedDiameter
      }
      if (maxScaleX < scale.x) {
        needReset = true
        lineRef.current.scale.x = maxScaleX
        width = maxSize
      }
      if (maxScaleY < scale.y) {
        needReset = true
        lineRef.current.scale.y = maxScaleY
        height = maxSize
      }
      if (maxScaleZ < scale.z) {
        needReset = true
        lineRef.current.scale.z = maxScaleZ
        depth = maxSize
      }

      if (needReset) {
        transformRef.current.detach()
        transformRef.current.attach(lineRef.current)
      }

      dispatch(
        setCuboid({
          center: lineRef.current.getWorldPosition(new Vector3()).toArray(),
          rotation: new Matrix3().setFromMatrix4(new Matrix4().extractRotation(lineRef.current.matrixWorld)).toArray(),
          extent: [width, height, depth],
        })
      )
    }
  }

  useEffect(() => {
    if (cuboid && !cuboidInfo) {
      setCuboidInfo({
        width: cuboid.extent[0],
        height: cuboid.extent[1],
        depth: cuboid.extent[2],
        position: new Vector3().fromArray(cuboid.center),
        quaternion: new Quaternion().setFromRotationMatrix(
          new Matrix4().setFromMatrix3(new Matrix3().fromArray(cuboid.rotation))
        ),
        edgesGeometry: new EdgesGeometry(new BoxGeometry(cuboid.extent[0], cuboid.extent[1], cuboid.extent[2])),
        rotationMatrix: new Matrix4(), // doesn't matter if it has any value
      })
    } else if (!cuboid && !cuboidAnchor) {
      setCuboidInfo(undefined)
    }
  }, [cuboid, cuboidInfo, cuboidAnchor])

  useEffect(() => {
    if (cuboidAnchor?.points.length !== EDITOR_REQUIRED_ANCHORS.cuboid) {
      prevCuboidAnchorRef.current = undefined
      return
    }

    // if cuboid has been initialized, no need to recalculate
    if (prevCuboidAnchorRef.current) {
      return
    }
    prevCuboidAnchorRef.current = cuboidAnchor

    const { width, depth, height, pose } = getCuboidFromPoints(pointsToVector3s(cuboidAnchor.points), convertedDiameter)

    const position = new Vector3().setFromMatrixPosition(pose)
    const rotationMatrix4 = new Matrix4().extractRotation(pose)
    const quaternion = new Quaternion().setFromRotationMatrix(pose)
    // use for drawing bounding box
    const edgesGeometry = new EdgesGeometry(new BoxGeometry(width, height, depth))

    dispatch(
      setCuboid({
        center: position.toArray(),
        rotation: new Matrix3().setFromMatrix4(rotationMatrix4).toArray(),
        extent: [width, height, depth],
      })
    )
    setCuboidInfo({
      width,
      depth,
      height,
      position,
      quaternion,
      edgesGeometry,
      rotationMatrix: rotationMatrix4,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedDiameter, cuboidAnchor])

  useEffect(() => {
    // if cuboid has not been initialized, no need to recalculate
    if (!cuboidInfo) {
      return
    }
    updateCuboid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedDiameter])

  useFrame(() => {
    if (lineRef.current && transformRef.current && (cuboidAnchor || cuboid)) {
      transformRef.current.visible = true
      lineRef.current.visible = true
      transformRef.current.attach(lineRef.current)
      if (selectedSubTool) {
        transformRef.current.setMode(selectedSubTool)
      }
    }
    // reset the values when cuboid destroyed
    if (lineRef.current && transformRef.current && !cuboidAnchor && !cuboid) {
      transformRef.current.visible = false
      transformRef.current.position.set(0, 0, 0)
      transformRef.current.setRotationFromMatrix(new Matrix4())
      transformRef.current.clear()
      lineRef.current.visible = false
      lineRef.current.position.set(0, 0, 0)
      lineRef.current.scale.set(1, 1, 1)
    }
  })

  useEffect(() => {
    // Remove some unused gizmos
    if (transformRef.current) {
      const gizmo = transformRef.current['gizmo'] as TransformControlsGizmo

      gizmo['gizmo'].scale.children.forEach((mesh: Mesh) => {
        removeGizmo(mesh)
      })
      gizmo['gizmo'].translate.children.forEach((mesh: Mesh) => {
        removeGizmo(mesh)
      })
      gizmo['gizmo'].rotate.children.forEach((mesh: Mesh) => {
        removeGizmo(mesh)
      })
      gizmo['picker'].scale.children.forEach((mesh: Object3D) => {
        removeGizmo(mesh)
      })
      gizmo['picker'].translate.children.forEach((mesh: Object3D) => {
        removeGizmo(mesh)
      })
      gizmo['picker'].rotate.children.forEach((mesh: Object3D) => {
        removeGizmo(mesh)
      })
    }
  }, [transformRef.current?.visible])

  if ((!cuboid && cuboidAnchor?.points.length !== EDITOR_REQUIRED_ANCHORS.cuboid) || !cuboidInfo) {
    return null
  }

  const colorKey = () => {
    if (selectedTool === EDITOR_TOOLS.COMMENT_CUBOID) {
      return 'comments'
    }
    if (selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID) {
      return 'pcdTrim'
    }
    return EDITOR_TOOL_KEYS[selectedTool]
  }

  const { dummyLength, dummyPositions, dummyRotation } = generateDummyFactors(
    editorCuboid,
    autoDetectSituation,
    convertedDiameter,
    cuboidDirection
  )
  return (
    <>
      {/* ref must be any */}
      <TransformControls ref={transformRef as any} size={0.75} onObjectChange={updateCuboid} space="local" />
      <group ref={groupRef as any} position={cuboidInfo.position} quaternion={cuboidInfo.quaternion}>
        <group ref={lineRef as any}>
          <lineSegments geometry={cuboidInfo.edgesGeometry}>
            <lineBasicMaterial color={EDITOR_FRAME_COLORS[colorKey()]} />
          </lineSegments>
        </group>
      </group>
      {!!editorCuboid && (
        <group
          quaternion={
            editorCuboid &&
            new Quaternion().setFromRotationMatrix(
              new Matrix4().setFromMatrix3(new Matrix3().fromArray(editorCuboid.rotation))
            )
          }
          position={editorCuboid && editorCuboid.center}
        >
          {(autoDetectSituation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS ||
            autoDetectSituation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC) &&
            dummyPositions.map((position) => (
              <mesh key={position.toString()} rotation={dummyRotation} position={new Vector3(...position)}>
                <DummyCylinderMesh diameter={convertedDiameter} length={dummyLength} />
              </mesh>
            ))}
          {autoDetectSituation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS &&
            dummyPositions.map((position) => (
              <mesh key={position.toString()} rotation={dummyRotation} position={new Vector3(...position)}>
                <DummyTorusMesh major_diameter={dummyLength - convertedDiameter} minor_diameter={convertedDiameter} />
              </mesh>
            ))}
        </group>
      )}
    </>
  )
}

export default CuboidFrame
