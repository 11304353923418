import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { EDITOR_CUBOID_DEFAULT_DIRECTION } from 'config/constants'

import { Cuboid, CuboidAnchor, CuboidDirection } from 'interfaces/interfaces'

interface CuboidState {
  // Cuboid object
  cuboid?: Cuboid

  // The direction for a cuboid
  cuboidDirection: CuboidDirection

  // The frame for creating a cuboid
  cuboidAnchor?: CuboidAnchor
}

const initialState: CuboidState = {
  cuboidDirection: EDITOR_CUBOID_DEFAULT_DIRECTION,
}

const cuboidSlice = createSlice({
  name: 'cuboid',
  initialState,
  reducers: {
    setCuboid: (state, action: PayloadAction<Cuboid | undefined>) => ({
      ...state,
      cuboid: action.payload,
    }),

    setCuboidDirection: (state, action: PayloadAction<CuboidDirection>) => ({
      ...state,
      cuboidDirection: action.payload,
    }),

    setCuboidAnchor: (state, action: PayloadAction<CuboidAnchor | undefined>) => ({
      ...state,
      cuboidAnchor: action.payload,
    }),

    // Reset the cuboid and cuboidAnchor states
    resetCuboidStates: (state) => ({
      ...state,
      cuboid: undefined,
      cuboidAnchor: undefined,
    }),

    // reducers for updating the multiple attributes when action is taken/finished.
    // It would complement the reducer for individual attributes above.
    commentClicked: (state, action: PayloadAction<Cuboid | undefined>) => ({
      ...state,
      cuboid: action.payload,
      cuboidAnchor: undefined,
    }),

    reset: () => initialState,
  },
})

export const { commentClicked, setCuboid, setCuboidDirection, setCuboidAnchor, resetCuboidStates, reset } =
  cuboidSlice.actions

export default cuboidSlice.reducer
