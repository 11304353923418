import { FC } from 'react'

import { useFrame } from '@react-three/fiber'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { Matrix3, Vector3 } from 'three'

/**
 * animate the camera center moving to target position
 *
 * @param {{ distance: any; position: any; center: any; finish: any; }} param0
 * @param {number} param0.distance distance from camera to target
 * @param {string} param0.position string representing the direction
 * @param {Vector3?} param0.center position of the target point
 * @param {() => void)} param0.finish callback function to be called when the animation is completed
 * @returns {*}
 */
const CameraCuboidAnimator: FC<{
  distance: number
  position: string
  center: Vector3 | undefined
  finish: () => void
}> = ({ distance, position, center, finish }) => {
  const { cuboid } = useSelector((state: RootState) => state.cuboid)
  const cuboidCenter = cuboid?.center ? new Vector3(...cuboid.center) : undefined

  // unit vectors for 6 directions
  const upVector = new Vector3(0, 0, 1)
  const downVector = new Vector3(0, 0, -1)
  const leftVector = new Vector3(-1, 0, 0)
  const rightVector = new Vector3(1, 0, 0)
  const frontVector = new Vector3(0, -1, 0)
  const backVector = new Vector3(0, 1, 0)

  const threshold = distance / 100

  /**
   * computes the vector (center + directionVector * distance)
   *
   * @param {Vector3} directionVector 3D vector whose norm is 1
   * @returns {Vector3} resultant vector
   */
  const getCameraGlobalTarget = (directionVector: Vector3) => {
    const targetVector = directionVector.clone().multiplyScalar(distance)
    return center?.clone().add(targetVector) || targetVector
  }

  /**
   * computes the vector (cuboid.rotation @ directionVector * distance)
   * or (cuboidCenter + directionVector * distance)
   *
   * @param {Vector3} directionVector 3D vector whose norm is 1
   * @returns {Vector3} resultant vector
   */
  const getCameraCuboidTarget = (directionVector: Vector3) => {
    if (cuboidCenter && cuboid?.rotation) {
      return directionVector
        .clone()
        .applyMatrix3(new Matrix3().fromArray(cuboid.rotation))
        .multiplyScalar(distance)
        .add(cuboidCenter)
    }
    return directionVector.clone().multiplyScalar(distance)
  }

  useFrame((state) => {
    if (!position) return null

    let target
    switch (position) {
      case 'UP':
        target = getCameraGlobalTarget(upVector)
        break
      case 'DOWN':
        target = getCameraGlobalTarget(downVector)
        break
      case 'LEFT':
        target = getCameraGlobalTarget(leftVector)
        break
      case 'RIGHT':
        target = getCameraGlobalTarget(rightVector)
        break
      case 'FRONT':
        target = getCameraGlobalTarget(frontVector)
        break
      case 'BACK':
        target = getCameraGlobalTarget(backVector)
        break
      case 'CUBE-UP':
        target = getCameraCuboidTarget(upVector)
        break
      case 'CUBE-DOWN':
        target = getCameraCuboidTarget(downVector)
        break
      case 'CUBE-LEFT':
        target = getCameraCuboidTarget(leftVector)
        break
      case 'CUBE-RIGHT':
        target = getCameraCuboidTarget(rightVector)
        break
      case 'CUBE-FRONT':
        target = getCameraCuboidTarget(frontVector)
        break
      case 'CUBE-BACK':
        target = getCameraCuboidTarget(backVector)
        break
      default:
        break
    }

    if (target) {
      if (state.camera.position.distanceTo(target) <= threshold) {
        finish()
        return null
      }
      state.camera.position.lerp(target, 0.2)
      state.camera.updateProjectionMatrix()
    }

    return null
  })

  return <mesh />
}

export default CameraCuboidAnimator
