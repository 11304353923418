import axios from 'extends/axios'
import { Matrix3 } from 'three'

import {
  API_PROCESS_MAP,
  EDITOR_EXTRA_SHAPE_KEYS,
  EDITOR_SHAPE_KEYS,
  EDITOR_SHAPE_TEMP_ID_PREFIX,
} from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import {
  Anchors,
  Cuboid,
  CuboidDirection,
  CuboidForMask,
  Cylinder,
  CylinderDetectionResult,
  ExtraShapeKey,
  Plane,
  PlaneDetectionResult,
  Rhombus,
  RhombusDetectionResult,
  ShapeKey,
  Shapes,
  ShapesId,
  Torus,
  TorusDetectionResult,
} from 'interfaces/interfaces'

import { rotateCuboid } from './Points'
import { millimeterToMeter } from './Util'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`
const GET_SHAPES_API_URL = (projectId: string) => `${PROJECTS_API_URL}/${projectId}/shapes`
const DETECT_SHAPES_API_URL = `${API_GATEWAY_URL}/detect-shapes`
const CREATE_SHAPES_API_URL = (projectId: string) => `${PROJECTS_API_URL}/${projectId}/shapes:batchCreate`
const DELETE_SHAPES_API_URL = (projectId: string) => `${PROJECTS_API_URL}/${projectId}/shapes:batchDelete`

/**
 * get shape objects in a project
 * @param {string} access_token Token
 * @param {string} project_id project ID
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Shapes} Shapes object
 */
export const getShapes = async (
  access_token: string,
  project_id: string,
  handleError: (err: unknown, processName: string) => void
): Promise<Shapes | null> => {
  const shapes = await axios
    .get<{ results: Shapes }>(GET_SHAPES_API_URL(project_id), {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.GET_SHAPES)
      return null
    })

  return shapes
}

/**
 * remove shape objects in a project
 * @param {string} access_token token
 * @param {string} project_id project ID
 * @param {ShapesId} shapesId all ids of cylinders, tori, planes
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Boolean} success or failure
 */
export const deleteShapes = async (
  access_token: string,
  project_id: string,
  shapesId: ShapesId,
  handleError: (err: unknown, processName: string) => void
): Promise<boolean> => {
  const filteredShapesId = {
    cylinders: shapesId.cylinders.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
    tori: shapesId.tori.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
    planes: shapesId.planes.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
    rhombi: shapesId.rhombi.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
  }
  // No need to send request if deleting shapes are not saved yet (temporarily detected)
  if (
    !filteredShapesId.cylinders.length &&
    !filteredShapesId.tori.length &&
    !filteredShapesId.planes.length &&
    !filteredShapesId.rhombi.length
  ) {
    return true
  }
  const result = await axios
    .post(
      DELETE_SHAPES_API_URL(project_id),
      { ...filteredShapesId },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DELETE_SHAPES)
      return false
    })

  return result
}

/**
 * save shape objects in a project
 * @param {string} access_token token
 * @param {string} project_id project ID
 * @param {ShapeKey | ExtraShapeKey} shape_type cylinders, tori, planes, rhombi
 * @param {
 *    CylinderDetectionResult[] | TorusDetectionResult[] |
 *    PlaneDetectionResult[] | RhombusDetectionResult[]
 * } shapes detected shapes result
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {Cylinder[] | Torus[] | Plane[] | Rhombus[]} a Shapes object
 */
export const saveShapes = async (
  access_token: string,
  project_id: string,
  shape_type: ShapeKey | ExtraShapeKey,
  shapes: CylinderDetectionResult[] | TorusDetectionResult[] | PlaneDetectionResult[] | RhombusDetectionResult[],
  handleError: (err: unknown, processName: string) => void
): Promise<Cylinder[] | Torus[] | Plane[] | Rhombus[] | null> => {
  const newShapes = await axios
    .post<{
      results: Cylinder[] | Torus[] | Plane[] | Rhombus[]
    }>(
      CREATE_SHAPES_API_URL(project_id),
      {
        shape_type,
        list_shapes: shapes.map((shape) => {
          if (shape_type !== EDITOR_EXTRA_SHAPE_KEYS.RHOMBI) {
            return {
              ...shape,
              registration_result: {
                ...(shape.registration_result || []),
                transformation: Object.values(shape.registration_result?.transformation || {}),
              },
            }
          }
          return {
            parameters_rhombus: (shape as RhombusDetectionResult).parameters_rhombus,
            is_virtual: true,
            transformation: Object.values(shape.registration_result?.transformation || {}),
          }
        }),
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.results)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.SAVE_SHAPES)
      return null
    })

  return newShapes
}

const getInternalShapeParameters = (diameter: number, shapeKey: ShapeKey) => {
  if (shapeKey === EDITOR_SHAPE_KEYS.CYLINDERS) {
    return { diameter: millimeterToMeter(diameter) }
  }
  if (shapeKey === EDITOR_SHAPE_KEYS.TORI) {
    return { minor_diameter: millimeterToMeter(diameter) }
  }
  return null
}

/**
 * detect shape objects in a project with specified anchors and mask regions
 * @param {string} access_token token
 * @param {string} downSampledFilePath file path
 * @param {string} shape_type cylinders, tori, planes
 * @param {Anchors} anchors all points with diameter
 * @param {CuboidForMask[]} maskRegions all mask regions
 * @param {boolean} use_outside_masks whether to use points outside of any masks
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {string} job's token - used to retrieve job's status
 */
export const detectShapes = async (
  access_token: string,
  downSampledFilePath: string,
  shape_type: ShapeKey,
  anchors: Anchors,
  maskRegions: CuboidForMask[],
  use_outside_masks: boolean,
  handleError: (err: unknown, processName: string) => void
): Promise<string | null> => {
  const requestBodyBase = {
    path_pcd: downSampledFilePath,
    shape_type,
    parameters_for_detecting_shapes: anchors[shape_type]
      .filter((anchor) => !anchor.deleted)
      .map((anchor) => ({
        internal_shape_parameters: getInternalShapeParameters(anchor.diameter || 0, shape_type),
        positions: anchor.points.map((point) => ({ x: point[0], y: point[1], z: point[2] })),
      })),
  }

  let requestBody = {}
  if (!maskRegions.length) {
    requestBody = {
      ...requestBodyBase,
    }
  } else {
    requestBody = {
      ...requestBodyBase,
      masked_regions: maskRegions.map((mask) => ({
        use_mask: !mask.invisible,
        center: mask.center,
        // invert rotation matrix to match the backend convention
        rotation: new Matrix3().fromArray(mask.rotation).clone().invert().toArray(),
        extent: mask.extent,
      })),
      use_outside_masks,
    }
  }

  const token = await axios
    .post<{
      job_token: string
    }>(`${DETECT_SHAPES_API_URL}`, requestBody, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.job_token)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DETECT_SHAPES)
      return null
    })

  return token
}

/**
 * detect shape objects in a project with specified cuboid and mask regions
 * @param {string} shapeKey cylinders type or torus type
 * @param {string} access_token token
 * @param {string} downSampledFilePath file path
 * @param {string} situation cylinders_on_axis, tori_on_axis, cylinders_on_arc
 * @param {number} diameter diameter
 * @param {Cuboid} cuboid cuboid's size, position, rotation
 * @param {CuboidForMask[]} maskRegions all mask regions
 * @param {boolean} use_outside_masks whether to use points outside of any masks
 * @param {function} handleError - Function to handle errors (open error modal).
 * @param {CuboidDirection} cuboidDirection cuboid's direction
 * @return {string} job's token - used to retrieve job's status
 */
export const autoDetectShapes = async (
  shapeKey: ShapeKey,
  access_token: string,
  downSampledFilePath: string,
  situation: string,
  diameter: number,
  cuboid: Cuboid,
  maskRegions: CuboidForMask[],
  use_outside_masks: boolean,
  handleError: (err: unknown, processName: string) => void,
  cuboidDirection: CuboidDirection
): Promise<string | null> => {
  const bounds = rotateCuboid(cuboid, cuboidDirection)
  const requestBodyBase = {
    path_pcd: downSampledFilePath,
    situation,
    internal_shape_parameters: getInternalShapeParameters(diameter, shapeKey),
    bounds: {
      center: bounds.center,
      extent: bounds.extent,
      // invert rotation matrix to match the backend convention
      rotation: new Matrix3().fromArray(bounds.rotation).invert().toArray(),
    },
  }

  let requestBody = {}
  if (!maskRegions.length) {
    requestBody = {
      ...requestBodyBase,
    }
  } else {
    requestBody = {
      ...requestBodyBase,
      masked_regions: maskRegions.map((mask) => ({
        use_mask: !mask.invisible,
        center: mask.center,
        // invert rotation matrix to match the backend convention
        rotation: new Matrix3().fromArray(mask.rotation).clone().invert().toArray(),
        extent: mask.extent,
      })),
      use_outside_masks,
    }
  }

  const token = await axios
    .post<{
      job_token: string
    }>(`${DETECT_SHAPES_API_URL}/bulk`, requestBody, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.job_token)
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DETECT_SHAPES)
      return null
    })

  return token
}

/**
 * To query the job status of detecting shape(s) either manually of automatically.
 * @param {string} access_token token
 * @param {string} jobToken job's token - get from the detect shape API
 * @param {function} handleError - Function to handle errors (open error modal).
 * @return {object | null} detection result
 */
export const checkJobStatusForShapeDetection = async (
  access_token: string,
  jobToken: string,
  handleError: (err: unknown, processName: string) => void
): Promise<{
  shape_type?: ShapeKey
  list_shapes: CylinderDetectionResult[] | TorusDetectionResult[] | PlaneDetectionResult[]
} | null> => {
  const shapes = await axios
    .get<{
      job_status: string
      results: {
        shape_type: ShapeKey
        list_shapes: CylinderDetectionResult[] | TorusDetectionResult[] | PlaneDetectionResult[]
      }
    }>(`${API_GATEWAY_URL}/job-status?token=${jobToken}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      if (response.data.job_status === 'SUCCEEDED') {
        return response.data.results
      }
      if (response.data.job_status === 'FAILED') {
        return { list_shapes: [] }
      }
      return null
    })
    .catch((err) => {
      handleError(err, API_PROCESS_MAP.DETECT_SHAPES)
      return null
    })
  return shapes
}
