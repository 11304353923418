import './Editor.css'

import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import mixpanel from 'mixpanel-browser'
import AppTitle from 'pages/dashboard/components/AppTitle'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import { BufferGeometry, Camera, Scene, Vector2, Vector3, WebGLRenderer } from 'three'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import useDebouncedEffect from 'hooks/DebouncedEffect'

import {
  COOKIE_EXPIRE,
  DEFAULT_EDITOR_SUB_TOOL,
  DEFAULT_EDITOR_TOOL,
  EDITOR_BACKGROUND_COOKIE_NAME,
  EDITOR_COLLAPSE_TYPES,
  EDITOR_CUBOID_KEY,
  EDITOR_EXTRA_SHAPE_KEYS,
  EDITOR_MEASURE_KEYS,
  EDITOR_REQUIRED_ANCHORS,
  EDITOR_SHAPES_SITUATIONS,
  EDITOR_SHAPE_KEYS,
  EDITOR_SHAPE_TEMP_ID_PREFIX,
  EDITOR_TOOLS,
  EDITOR_TOOL_KEYS,
  MODAL_TYPES,
} from 'config/constants'
import {
  EDITOR_ACTION_BUTTON_MAX_WIDTH,
  EDITOR_ACTION_BUTTON_MIN_WIDTH,
  EDITOR_DEFAULT_BACKGROUND,
} from 'config/styles'

import {
  AnchorItem,
  AnchorPoints,
  Anchors,
  CuboidForMask,
  CuboidKey,
  FocusedPoint,
  IFCFile,
  InspectionItem,
  MeasureKey,
  Overlap,
  Plane,
  PointArray,
  Project,
  Rhombus,
  ShapeGroup,
  ShapeKey,
  Shapes,
  ShapesDistances,
  ShapesId,
  SpacerInspectionItem,
} from 'interfaces/interfaces'

import { deleteDistance, getDistances } from 'services/Distances'
import { calculateCenterAndDistance, generateSpacerAnnotationDistances } from 'services/Editor'
import { getMeanDistanceAndIndividualDistance } from 'services/InspectionSheet'
import { getMaskingRegions } from 'services/MaskingRegion'
import { deleteOverlap, getOverlaps } from 'services/Overlaps'
import { fixVertexOnNormal, pointsToVector3s } from 'services/Points'
import { getProject } from 'services/Projects'
import { deleteShapes, getShapes } from 'services/Shapes'
import { parsePlane, parseRhombus } from 'services/Spacers'
import { needClearAnchorFrames } from 'services/Util'
import { decideActionPermission } from 'services/Validation'

import TopNav, { NAV_TYPES } from '../components/TopNav'
import CADExportButton from './actions/CADExportButton'
import ComputeOverlapButton from './actions/ComputeOverlapButton'
import ConvertButton from './actions/ConvertButton'
import ConvertSpacerAnnotationButton from './actions/ConvertSpacerAnnotationButton'
import ConvertVirtualPlaneButton from './actions/ConvertVirtualPlaneButton'
import InspectionButton from './actions/InspectionButton'
import SaveButton from './actions/SaveButton'
import SaveDistanceButton from './actions/SaveDistanceButton'
import SaveMaskRegionButton from './actions/SaveMaskRegionButton'
import ShapeGroupRepickButton from './actions/ShapeGroupRepickButton'
import InfoPanels from './infoPanels/InfoPanels'
import MainCanvas from './mainCanvas/MainCanvas'
import { resetCuboidStates, reset as resetCuboidStore, setCuboidAnchor } from './store/cuboid'
import {
  cuboidAnchorAdded,
  distanceComputed,
  reset as resetEditorStore,
  setExpandedPanels,
  setIsJobRunning,
  setIsToolProcessing,
  spacerAnchorAdded,
} from './store/editor'
import {
  reset as resetAnnotationStore,
  setSpacerAnnotationAnchors,
  setSpacerAnnotations,
} from './store/spacerAnnotation'
import {
  resetCommentPopupPosition,
  reset as resetCommentStore,
  setMovingCommentCartesianPosition,
} from './store/temporalComment'
import AssistantBar from './toolbar/AssistantBar'
import InstructionBar from './toolbar/InstructionBar'
import SubToolbar from './toolbar/SubToolbar'
import Toolbar from './toolbar/Toolbar'

const Editor: FC = () => {
  const { t } = useTranslation(['projects'])

  //* プロジェクトデータアクセス用
  const { projectGroups, invitedProjectGroups } = useContext(ProjectsContext)
  const { userLoaded, userType, userTypeForOrganizations, organizations, getAccessToken } = useContext(UserContext)
  const { showModal, handleError } = useContext(GlobalModalContext)
  const [cookies, setCookie] = useCookies([EDITOR_BACKGROUND_COOKIE_NAME])
  const { user } = useAuth0()
  const dispatch = useAppDispatch()

  const { cuboid, cuboidAnchor } = useSelector((state: RootState) => state.cuboid)
  const { spacerAnnotations, spacerAnnotationAnchors } = useSelector((state: RootState) => state.spacerAnnotation)
  const { isJobRunning, baseDiameter } = useSelector((state: RootState) => state.editor)
  const { isMovingComment } = useSelector((state: RootState) => state.temporal_comment)

  // Toolbar
  const [selectedTool, setSelectedTool] = useState(DEFAULT_EDITOR_TOOL)
  const [selectedSubTool, setSelectedSubTool] = useState<string>()
  const [isAllActionsDisabled, setIsAllActionsDisabled] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [selectedPoint, setSelectedPoint] = useState<FocusedPoint>()
  const [hoveredPoint, setHoveredPoint] = useState<FocusedPoint>()
  const [hoveredShapeId, setHoverShapeId] = useState('')
  const [selectedShapeIds, setSelectedShapeIds] = useState<string[]>([])
  const [isLayerModifying, setIsLayerModifying] = useState(false)
  const [autoDetectSituation, setAutoDetectSituation] = useState('')
  const [selectedInspectionItem, setSelectedInspectionItem] = useState<InspectionItem>()
  const [selectedSpacerInspectionItem, setSelectedSpacerInspectionItem] = useState<SpacerInspectionItem>()
  const [shapesDistancesVisible, setShapesDistancesVisible] = useState(false)
  const [maskRegions, setMaskRegions] = useState<CuboidForMask[]>([])
  const [maskRegionsOutsideVisible, setMaskRegionsOutsideVisible] = useState(false)
  const [canvasRenderers, setCanvasRenderers] = useState<{ gl: WebGLRenderer; scene: Scene; camera: Camera }>()
  const [selectedIFCGeometryIndex, setSelectedIFCGeometryIndex] = useState<{ fileIndex: number; index: number }>()

  // Project
  const { project_id } = useParams<{ project_id: string }>()
  const [project, setProject] = useState<Project>()

  const [overlaps, setOverlaps] = useState<Overlap[]>([])
  const [anchors, setAnchors] = useState<Anchors>(INITIAL_SHAPE_STATE())
  const [distanceAnchors, setDistanceAnchors] = useState<AnchorItem[]>([])
  const [shapes, setShapes] = useState<Shapes>(INITIAL_SHAPE_STATE())
  const [shapeGroups, setShapeGroups] = useState<ShapeGroup[]>([])
  const [shapesDistances, setShapesDistances] = useState<{
    shapes: Shapes
    distances: ShapesDistances
    situation: string
  }>({
    shapes: INITIAL_SHAPE_STATE(),
    distances: INITIAL_SHAPE_STATE(),
    situation: '',
  })
  const [shapesPreviewDistances, setShapesPreviewDistances] = useState<ShapesDistances>()
  const [processingAnchor, setProcessingAnchor] = useState<PointArray>()
  const [isMouseDown, setIsMouseDown] = useState(false)
  const actionPanelRef = useRef<HTMLDivElement>(null)
  const selectedCylinders = shapes.cylinders.filter((cylinder) => selectedShapeIds.includes(cylinder.shape_id))
  const [IFCFiles, setIFCFiles] = useState<IFCFile[]>([])
  const [IFCGeometries, setIFCGeometries] = useState<BufferGeometry[][]>([])
  const [cameraFocusPoint, setCameraFocusPoint] = useState<Vector3>()

  //* Prefixed cube positions
  const [movingPrefixedPosition, setMovingPrefixedPosition] = useState('')

  const projectGroup = projectGroups
    .concat(invitedProjectGroups)
    .find((proj) => proj.project_group_id === project?.project_group_id)

  // Check if the project is owned by any organization the user belongs to
  const orgOwningProject = organizations.find((org) => org.organization_id === projectGroup?.organization_id)
  const isOwner = orgOwningProject !== undefined
  // userTypeForOrganizations should be used if the user belongs to the organization owning the project.
  // otherwise, use userType.
  let userTypeForPermission = userType
  if (isOwner) {
    const organizationId = orgOwningProject.organization_id
    userTypeForPermission = userTypeForOrganizations[organizationId]
  }
  const isInvited = invitedProjectGroups.some((proj) => proj.project_group_id === project?.project_group_id)

  const permissions = decideActionPermission(userTypeForPermission, isOwner, isInvited).ACTIONS_WITH_PROJECT
  const isAllowedModify = permissions.MODIFY
  const isAllowedView = permissions.BROWSE

  const addGeneralAnchor = (
    addedPoint: PointArray,
    anchorPoints: AnchorPoints[],
    shapeKey: ShapeKey | CuboidKey | MeasureKey
  ) => {
    const newAnchorPoints = [...anchorPoints]
    const lastAnchor = newAnchorPoints.length ? newAnchorPoints[newAnchorPoints.length - 1] : undefined

    // If there is no anchor yet, or the last anchor already has enough required points
    // -> add a new anchor
    if (!lastAnchor?.points?.length || lastAnchor.points.length >= EDITOR_REQUIRED_ANCHORS[shapeKey]) {
      newAnchorPoints.push({
        points: [addedPoint],
        diameter: baseDiameter,
      })
      dispatch(setIsToolProcessing(true))
      setSelectedPoint({ anchorIndex: newAnchorPoints.length - 1, pointIndex: 0, shapeKey })
    } else {
      // Else, add this point as the end point of the last anchor
      lastAnchor.points.push(addedPoint)
      newAnchorPoints[newAnchorPoints.length - 1] = lastAnchor
      // Keep drawing live line if required points are not enough
      dispatch(setIsToolProcessing(lastAnchor.points.length < EDITOR_REQUIRED_ANCHORS[shapeKey]))
      setSelectedPoint({ anchorIndex: newAnchorPoints.length - 1, pointIndex: lastAnchor.points.length - 1, shapeKey })
    }

    setProcessingAnchor(undefined)
    return newAnchorPoints
  }

  const addAnchor = (addedPoint: PointArray) => {
    const shapeKey = EDITOR_TOOL_KEYS[selectedTool]

    if (!shapeKey) {
      return
    }

    setAnchors({ ...anchors, [shapeKey]: addGeneralAnchor(addedPoint, anchors[shapeKey], shapeKey) })
    // TODO: refactor below as a reducer function in store/editor.ts (anchorAdded)
    if (selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID) {
      dispatch(setExpandedPanels([EDITOR_COLLAPSE_TYPES.maskRegion]))
    } else {
      dispatch(setExpandedPanels([EDITOR_COLLAPSE_TYPES.detecting, EDITOR_COLLAPSE_TYPES.diameter]))
    }
  }

  // There are two event listening for adding distance anchor.
  // 1: click on point cloud object
  // 2: click on mesh object
  // To prevent duplicating point, select the point which closer to camera
  const addDistanceAnchor = useCallback(
    (addedPoint: PointArray, mousePosition: Vector2, cameraDistance: number, timestamp: number) => {
      const anchorPoints = [...distanceAnchors]
      const lastAnchor = anchorPoints.length ? anchorPoints[anchorPoints.length - 1] : undefined

      // Check if the last picked point is from the same mouse click event with this being added point
      if (lastAnchor && lastAnchor.points.length === lastAnchor.pickedInfo?.length) {
        const lastPickedInfo = lastAnchor.pickedInfo[lastAnchor.points.length - 1]
        // Check if the timestamp is within 1 seconds, it only checking together with if the positions are the same
        // Therefore, there will no performance affect to the other picking point features
        if (Math.abs(lastPickedInfo.timestamp - timestamp) <= 1 && lastPickedInfo.mousePosition.equals(mousePosition)) {
          // If true, take the one that closer to camera
          // Switching point will not update states of isToolProcessing and selectedPoint
          if (cameraDistance < lastPickedInfo.cameraDistance) {
            lastAnchor.points[lastAnchor.points.length - 1] = addedPoint
            lastAnchor.pickedInfo[lastAnchor.points.length - 1] = { mousePosition, cameraDistance, timestamp }
            // save back the points
            anchorPoints[anchorPoints.length - 1] = lastAnchor
          }
          // Clear the processing state and stop this adding process here
          setProcessingAnchor(undefined)
          setDistanceAnchors(anchorPoints)
          return
        }
      }

      if (!lastAnchor?.points.length || lastAnchor.points.length >= 2) {
        // If there is no anchor yet, or the last anchor already has enough required points
        // -> add a new anchor
        anchorPoints.push({
          points: [addedPoint],
          pickedInfo: [
            {
              mousePosition,
              cameraDistance,
              timestamp,
            },
          ],
        })
        dispatch(setIsToolProcessing(true))
        setSelectedPoint({
          anchorIndex: anchorPoints.length - 1,
          pointIndex: 0,
          shapeKey: EDITOR_MEASURE_KEYS.DISTANCE,
        })
      } else {
        // Else, add this point as the end point of the last anchor
        lastAnchor.points.push(addedPoint)
        if (lastAnchor.pickedInfo) {
          lastAnchor.pickedInfo.push({
            mousePosition,
            cameraDistance,
            timestamp,
          })
        }
        const distanceFactors = calculateCenterAndDistance(lastAnchor)
        lastAnchor.center = distanceFactors?.[0]
        lastAnchor.distance = distanceFactors?.[1]

        anchorPoints[anchorPoints.length - 1] = lastAnchor
        // Keep drawing live line if required points are not enough
        dispatch(setIsToolProcessing(lastAnchor.points.length < 2))
        setSelectedPoint({
          anchorIndex: anchorPoints.length - 1,
          pointIndex: lastAnchor.points.length - 1,
          shapeKey: EDITOR_MEASURE_KEYS.DISTANCE,
        })

        // track with mixpanel
        mixpanel.track('Compute distance', {
          'Inspection area ID': project_id,
          Distance: lastAnchor.distance,
          Situation: 'create distance object',
        })
      }

      setProcessingAnchor(undefined)
      setDistanceAnchors(anchorPoints)
      dispatch(distanceComputed())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [distanceAnchors, project_id]
  )

  const addCuboidAnchor = (addedPoint: Vector3) => {
    if (cuboidAnchor && cuboidAnchor.points.length >= EDITOR_REQUIRED_ANCHORS.cuboid) {
      return
    }

    const points =
      cuboidAnchor?.points.length === EDITOR_REQUIRED_ANCHORS.cuboid - 1
        ? fixVertexOnNormal(pointsToVector3s(cuboidAnchor.points)).map((point) => point.toArray())
        : cuboidAnchor?.points || []
    const newPoints = [...points, addedPoint.toArray()]

    // Keep drawing live line if required points are not enough
    const cuboidIsComplete = newPoints.length === EDITOR_REQUIRED_ANCHORS.cuboid
    setProcessingAnchor(undefined)
    dispatch(
      setCuboidAnchor({
        diameter: cuboidAnchor?.diameter || baseDiameter || 0,
        points: newPoints,
      })
    )
    setSelectedPoint({
      anchorIndex: 0,
      pointIndex: newPoints.length - 1,
      shapeKey: EDITOR_CUBOID_KEY,
    })
    dispatch(cuboidAnchorAdded(cuboidIsComplete))
    if (cuboidIsComplete) {
      // track with mixpanel
      mixpanel.track('Create cuboid region', {
        'Inspection area ID': project?.project_id,
        'Selected tool': selectedTool,
        Method: 'Clicks on multiple points',
      })
    }
  }

  const addSpacerAnnotationAnchor = (addedPoint: PointArray) => {
    const filteredAnchors = spacerAnnotationAnchors.filter((s) => !s.deleted)
    // Only allow adding 1 set of anchors
    if (filteredAnchors.length === 1 && filteredAnchors[0].points.length === EDITOR_REQUIRED_ANCHORS.spacerAnnotation) {
      return
    }

    // deep copy as redux state is immutable
    const spacerAnchorEditable = cloneDeep(spacerAnnotationAnchors)
    const annotationAnchors = addGeneralAnchor(addedPoint, spacerAnchorEditable, EDITOR_MEASURE_KEYS.SPACER_ANNOTATION)

    // TODO: can we rewrite as a single action to update the states in 2 slices at once?
    dispatch(setSpacerAnnotationAnchors(generateSpacerAnnotationDistances(annotationAnchors)))
    dispatch(spacerAnchorAdded())
  }

  const updateShapeStatus = useCallback(
    (props: Record<'invisible', boolean>, index: number, shapeKey: ShapeKey) => {
      const statuses = [...shapes[shapeKey]]

      if (index < 0 || index >= statuses.length) {
        return
      }

      statuses[index] = { ...statuses[index], ...props }
      setShapes({ ...shapes, [shapeKey]: statuses })
    },
    [shapes]
  )
  const updateShapeGroupStatus = useCallback(
    (props: Record<'invisible', boolean>, groupId: string, shapeKey: ShapeKey) => {
      const group = shapeGroups.find((g) => g.grouping_shape_type_id === groupId)
      const statuses = [...shapes[shapeKey]].map((shape) => {
        if (group?.cylinder_ids?.includes(shape.shape_id) || group?.torus_ids?.includes(shape.shape_id)) {
          return { ...shape, ...props }
        }
        return shape
      })
      setShapes({ ...shapes, [shapeKey]: statuses })

      if ('invisible' in props) {
        // track with mixpanel
        mixpanel.track('Change visibility of shape objects', {
          'Inspection area ID': project_id,
          Granuarity: 'type',
          'Visibility (new)': !props.invisible,
          'Visibility (old)': props.invisible,
          'Shape type': shapeKey,
          'Type ID': groupId,
          'Shape number': group?.cylinder_ids?.length || group?.torus_ids?.length,
        })
      }
    },
    [project_id, shapeGroups, shapes]
  )
  const updateAllShapesStatus = useCallback(
    (props: Record<'invisible', boolean>, shapeKey: ShapeKey) => {
      const statuses = [...shapes[shapeKey]].map((status) => ({ ...status, ...props }))
      setShapes({ ...shapes, [shapeKey]: statuses })

      if ('invisible' in props) {
        // track with mixpanel
        mixpanel.track('Change visibility of shape objects', {
          'Inspection area ID': project_id,
          Granuarity: 'all',
          'Visibility (new)': !props.invisible,
          'Visibility (old)': props.invisible,
          'Shape type': shapeKey,
          'Shape number': shapes[shapeKey].length,
        })
      }
    },
    [shapes, project_id]
  )

  const updateAnchorPoint = useCallback(
    (pointInfo: FocusedPoint, point: PointArray) => {
      const points = [...anchors[pointInfo.shapeKey as ShapeKey]]
      points[pointInfo.anchorIndex].points[pointInfo.pointIndex] = point
      setAnchors({ ...anchors, [pointInfo.shapeKey]: points })
    },
    [anchors]
  )
  const updateDistanceAnchorPoint = useCallback(
    (pointInfo: FocusedPoint, point: PointArray) => {
      const points = [...distanceAnchors]
      points[pointInfo.anchorIndex].points[pointInfo.pointIndex] = point

      const distanceFactors = calculateCenterAndDistance(points[pointInfo.anchorIndex])
      points[pointInfo.anchorIndex].center = distanceFactors?.[0]
      points[pointInfo.anchorIndex].distance = distanceFactors?.[1]
      points[pointInfo.anchorIndex].modified = true

      setDistanceAnchors(points)
    },
    [distanceAnchors]
  )

  const updateAnchorStatus = useCallback(
    (props: Record<string, boolean>, index: number, shapeKey: ShapeKey) => {
      const statuses = [...anchors[shapeKey]]

      if (index < 0 || index >= statuses.length) {
        return
      }

      statuses[index] = { ...statuses[index], ...props }
      setAnchors({ ...anchors, [shapeKey]: statuses })
    },
    [anchors]
  )
  const updateAllAnchorsStatus = useCallback(
    (props: Record<string, boolean>, shapeKey: ShapeKey) => {
      const statuses = [...anchors[shapeKey]].map((status) => ({ ...status, ...props }))
      setAnchors({ ...anchors, [shapeKey]: statuses })
    },
    [anchors]
  )

  const updateDistanceAnchorStatus = useCallback(
    (props: Record<string, boolean>, index: number) => {
      const statuses = [...distanceAnchors]

      if (index < 0 || index >= statuses.length) {
        return
      }

      statuses[index] = { ...statuses[index], ...props }
      setDistanceAnchors(statuses)
    },
    [distanceAnchors]
  )
  const updateAllDistanceAnchorsStatus = useCallback(
    (props: Record<string, boolean>) => {
      const statuses = distanceAnchors.map((status) => ({ ...status, ...props }))
      setDistanceAnchors(statuses)

      if ('invisible' in props) {
        // track with mixpanel
        mixpanel.track('Change visibility of distance', {
          'Inspection area ID': project_id,
          Granuarity: 'all',
          'Visibility (new)': !props.invisible,
          'Visibility (old)': props.invisible,
          'Distance number': distanceAnchors.length,
        })
      }
    },
    [distanceAnchors, project_id]
  )

  const updateSpacerAnchorStatus = useCallback(
    (props: Record<string, boolean>, index: number) => {
      // deep copy as redux state is immutable
      const statuses = [...cloneDeep(spacerAnnotationAnchors)]

      if (index < 0 || index >= statuses.length) {
        return
      }

      statuses[index] = { ...statuses[index], ...props }
      dispatch(setSpacerAnnotationAnchors(statuses))
    },
    [dispatch, spacerAnnotationAnchors]
  )

  const updateSpacerAnnotationAnchorPoint = useCallback(
    (pointInfo: FocusedPoint, point: PointArray) => {
      const points = [...cloneDeep(spacerAnnotationAnchors)] // deep copy as redux state is immutable
      points[pointInfo.anchorIndex].points[pointInfo.pointIndex] = point
      dispatch(setSpacerAnnotationAnchors(generateSpacerAnnotationDistances(points, pointInfo.anchorIndex)))
    },
    [dispatch, spacerAnnotationAnchors]
  )

  const updateAllSelectedShapesStatus = useCallback(
    (props: Record<'invisible', boolean>) => {
      const newStatuses = { ...shapes }

      selectedShapeIds.forEach((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.CYLINDERS].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex >= 0) {
          newStatuses.cylinders[shapeIndex] = { ...newStatuses.cylinders[shapeIndex], ...props }
        }
      })

      selectedShapeIds.forEach((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.TORI].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex >= 0) {
          newStatuses.tori[shapeIndex] = { ...newStatuses.tori[shapeIndex], ...props }
        }
      })

      selectedShapeIds.forEach((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.PLANES].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex >= 0) {
          newStatuses.planes[shapeIndex] = { ...newStatuses.planes[shapeIndex], ...props }
        }
      })

      setShapes(newStatuses)

      if ('invisible' in props) {
        // track with mixpanel
        mixpanel.track('Change visibility of shape objects', {
          'Inspection area ID': project_id,
          Granuarity: 'selected',
          'Visibility (new)': !props.invisible,
          'Visibility (old)': props.invisible,
          // TODO: count number of selected shapes for each shape type
        })
      }
    },
    [selectedShapeIds, shapes, project_id]
  )

  const updateOverlapStatus = useCallback(
    (props: Record<string, boolean>, index: number) => {
      const statuses = [...overlaps]

      if (index < 0 || index >= statuses.length) {
        return
      }

      statuses[index] = { ...statuses[index], ...props }
      setOverlaps(statuses)
    },
    [overlaps]
  )
  const updateAllOverlapsStatus = useCallback(
    (props: Record<string, boolean>) => {
      const statuses = [...overlaps].map((status) => ({ ...status, ...props }))
      setOverlaps(statuses)
    },
    [overlaps]
  )

  const updateSpacerAnnotationStatus = useCallback(
    (props: Record<string, boolean>, index: number) => {
      // deep copy as redux state is immutable
      const statuses = [...cloneDeep(spacerAnnotations)]

      if (index < 0 || index >= statuses.length) {
        return
      }

      statuses[index] = { ...statuses[index], ...props }
      dispatch(setSpacerAnnotations(statuses))
    },
    [dispatch, spacerAnnotations]
  )
  const updateAllSpacerAnnotationsStatus = useCallback(
    (props: Record<string, boolean>) => {
      const statuses = [...spacerAnnotations].map((status) => ({ ...status, ...props }))
      dispatch(setSpacerAnnotations(statuses))
    },
    [dispatch, spacerAnnotations]
  )

  const updateSelectedPointValue = useCallback(
    (newValue: PointArray) => {
      if (!selectedPoint) {
        return
      }

      // cannot update anchor point position for detected shapes distances
      if (
        selectedPoint.shapeKey === EDITOR_MEASURE_KEYS.DETECTED_CYLINDERS_DISTANCE ||
        selectedPoint.shapeKey === EDITOR_MEASURE_KEYS.DETECTED_TORI_DISTANCE
      ) {
        return
      }

      if (selectedPoint.shapeKey === EDITOR_MEASURE_KEYS.DISTANCE) {
        const anchorPoints = [...distanceAnchors]
        anchorPoints[selectedPoint.anchorIndex].points[selectedPoint.pointIndex] = newValue

        const distanceFactors = calculateCenterAndDistance(anchorPoints[selectedPoint.anchorIndex])
        anchorPoints[selectedPoint.anchorIndex].center = distanceFactors?.[0]
        anchorPoints[selectedPoint.anchorIndex].distance = distanceFactors?.[1]

        setDistanceAnchors(anchorPoints)
        // cannot update anchor point position for cuboid detection
      } else if (
        selectedPoint.shapeKey === EDITOR_SHAPE_KEYS.CYLINDERS ||
        selectedPoint.shapeKey === EDITOR_SHAPE_KEYS.PLANES ||
        selectedPoint.shapeKey === EDITOR_SHAPE_KEYS.TORI
      ) {
        const anchorPoints = [...anchors[selectedPoint.shapeKey]]
        anchorPoints[selectedPoint.anchorIndex].points[selectedPoint.pointIndex] = newValue

        setAnchors({ ...anchors, [selectedPoint.shapeKey]: anchorPoints })
      }
    },
    [anchors, distanceAnchors, selectedPoint]
  )
  const updateSelectedPointDiameter = useCallback(
    (newValue: number) => {
      if (!selectedPoint) {
        return
      }

      if (selectedPoint.shapeKey === EDITOR_CUBOID_KEY) {
        if (cuboidAnchor) {
          dispatch(setCuboidAnchor({ ...cuboidAnchor, diameter: newValue }))
        }
      } else {
        const anchorPoints = [...anchors[selectedPoint.shapeKey as ShapeKey]]
        anchorPoints[selectedPoint.anchorIndex].diameter = newValue

        setAnchors({ ...anchors, [selectedPoint.shapeKey]: anchorPoints })
      }
    },
    [anchors, cuboidAnchor, dispatch, selectedPoint]
  )

  const deleteSelectedShapes = useCallback(
    (forSelectedShapes: boolean, index?: number, shapeKey?: ShapeKey) => {
      if (!project?.project_id) {
        return false
      }

      const shapesId: ShapesId = INITIAL_SHAPE_STATE()
      let totalShapes = 0

      if (forSelectedShapes) {
        selectedShapeIds.forEach((id) => {
          if (shapes.cylinders.some((shape) => shape.shape_id === id)) {
            shapesId.cylinders.push(id)
            totalShapes += 1
          }
        })
        selectedShapeIds.forEach((id) => {
          if (shapes.tori.some((shape) => shape.shape_id === id)) {
            shapesId.tori.push(id)
            totalShapes += 1
          }
        })
        selectedShapeIds.forEach((id) => {
          if (shapes.planes.some((shape) => shape.shape_id === id)) {
            shapesId.planes.push(id)
            totalShapes += 1
          }
        })
      } else if (index !== undefined && shapeKey) {
        shapesId[shapeKey] = [shapes[shapeKey][index].shape_id]
        totalShapes = 1
      }

      showModal({
        title: t('main_canvas.modals.delete_shape.title', { ns: 'projects', count: totalShapes }),
        body: <Text>{t('main_canvas.modals.delete_shape.text', { ns: 'projects' })}</Text>,
        confirmText: t('main_canvas.modals.delete_shape.confirm', { ns: 'projects' }),
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirm: () => {
          void (async () => {
            setIsLayerModifying(true)
            const token = await getAccessToken()
            if (!token) {
              return false
            }

            const deleteResult = await deleteShapes(token, project.project_id, shapesId, handleError)

            // track with mixpanel
            mixpanel.track('Delete saved/unsaved shapes', {
              'Inspection area ID': project.project_id,
              'Number of deleted shapes (cylinder)': shapesId.cylinders.length,
              'Number of deleted shapes (torus)': shapesId.tori.length,
              'Number of deleted shapes (plane)': shapesId.planes.length,
            })

            if (deleteResult) {
              const newShapes = { ...shapes }
              newShapes.cylinders = newShapes.cylinders.filter((shape) => !shapesId.cylinders.includes(shape.shape_id))
              newShapes.tori = newShapes.tori.filter((shape) => !shapesId.tori.includes(shape.shape_id))
              newShapes.planes = newShapes.planes.filter((shape) => !shapesId.planes.includes(shape.shape_id))
              setShapes(newShapes)

              const newSelectedShapeIds = selectedShapeIds.filter(
                (id) => !shapesId.cylinders.includes(id) && !shapesId.tori.includes(id) && !shapesId.planes.includes(id)
              )
              setSelectedShapeIds(newSelectedShapeIds)

              // recalculate the shape distances,
              // no need to recalculate the distances with planes,
              // because this deleting action only affects the detected shapes by cuboid tool
              const newDistances = { ...shapesDistances }
              if (shapesDistances.shapes.cylinders.some((shape) => shapesId.cylinders.includes(shape.shape_id))) {
                const newDistanceShapes = shapesDistances.shapes.cylinders.filter(
                  (shape) => !shapesId.cylinders.includes(shape.shape_id)
                )
                // no need to calculate distances if there is less than 2 shapes
                if (newDistanceShapes.length < 2) {
                  newDistances.distances.cylinders = []
                } else {
                  const meanDistance = await getMeanDistanceAndIndividualDistance(
                    token,
                    shapesDistances.situation,
                    newDistanceShapes,
                    handleError
                  )
                  if (meanDistance) {
                    newDistances.shapes.cylinders = [...newDistanceShapes]
                    newDistances.distances.cylinders = meanDistance?.individual_distance || []
                  }
                }
              } else if (shapesDistances.shapes.tori.some((shape) => shapesId.tori.includes(shape.shape_id))) {
                const newDistanceShapes = shapesDistances.shapes.tori.filter(
                  (shape) => !shapesId.tori.includes(shape.shape_id)
                )
                // no need to calculate distances if there is less than 2 shapes
                if (newDistanceShapes.length < 2) {
                  newDistances.distances.tori = []
                } else {
                  const meanDistance = await getMeanDistanceAndIndividualDistance(
                    token,
                    shapesDistances.situation,
                    newDistanceShapes,
                    handleError
                  )
                  if (meanDistance) {
                    newDistances.shapes.tori = [...newDistanceShapes]
                    newDistances.distances.tori = meanDistance?.individual_distance || []
                  }
                }
              }
              setShapesDistances(newDistances)

              // refetch the overlaps, if cylinders have been deleted
              if (shapesId.cylinders.length) {
                const newOverlaps = await getOverlaps(token, project.project_id, handleError)
                setOverlaps(newOverlaps || [])
              }
            }

            setIsLayerModifying(false)
            return deleteResult
          })()
          return true
        },
      })

      return true
    },
    [getAccessToken, project, selectedShapeIds, shapes, shapesDistances, t, handleError, showModal]
  )
  const updateShapeGroups = useCallback((groups: ShapeGroup[]) => {
    setShapeGroups(groups)
  }, [])

  const deleteSelectedOverlaps = useCallback(
    (index: number) => {
      if (!project?.project_id) {
        return false
      }

      showModal({
        title: t('main_canvas.modals.delete_overlap.title', { ns: 'projects' }),
        body: <Text>{t('main_canvas.modals.delete_overlap.text', { ns: 'projects' })}</Text>,
        confirmText: t('main_canvas.modals.delete_overlap.confirm', { ns: 'projects' }),
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirm: () => {
          void (async () => {
            setIsLayerModifying(true)

            // No need to call API if removing temporary overlap
            let deleteResult = true
            if (!overlaps[index].overlap_length_id?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) {
              const token = await getAccessToken()
              if (!token) {
                return false
              }

              deleteResult = await deleteOverlap(
                token,
                project.project_id,
                overlaps[index].overlap_length_id || '',
                handleError
              )

              // track with mixpanel
              mixpanel.track('Delete overlap length', {
                'Inspection area ID': project.project_id,
              })
            }

            const newOverlaps = [...overlaps]
            if (deleteResult) {
              newOverlaps.splice(index, 1)
              setOverlaps(newOverlaps)
            }

            setIsLayerModifying(false)
            return deleteResult
          })()
          return true
        },
      })

      return true
    },
    [t, getAccessToken, overlaps, project, handleError, showModal]
  )

  const deleteSelectedSpacerAnnotations = useCallback(
    (forSelectedShapes: boolean, index?: number) => {
      if (!project?.project_id) {
        return false
      }

      const shapesId: ShapesId = INITIAL_SHAPE_STATE()
      let totalShapes = 0

      if (forSelectedShapes) {
        selectedShapeIds.forEach((id) => {
          const spacerShape = spacerAnnotations.find((shape) => shape.shape_id === id)
          if (spacerShape?.shape_type === EDITOR_SHAPE_KEYS.PLANES) {
            shapesId.planes.push(id)
            totalShapes += 1
          }
          if (spacerShape?.shape_type === EDITOR_EXTRA_SHAPE_KEYS.RHOMBI) {
            shapesId.rhombi.push(id)
            totalShapes += 1
          }
        })
      } else if (index !== undefined) {
        const spacerShape = spacerAnnotations[index]
        if (spacerShape.shape_type === EDITOR_SHAPE_KEYS.PLANES) {
          shapesId.planes.push(spacerShape.shape_id)
        }
        if (spacerShape.shape_type === EDITOR_EXTRA_SHAPE_KEYS.RHOMBI) {
          shapesId.rhombi.push(spacerShape.shape_id)
        }
        totalShapes = 1
      }

      showModal({
        title: t('main_canvas.modals.delete_spacer_grid.title', { ns: 'projects', count: totalShapes }),
        body: <Text>{t('main_canvas.modals.delete_spacer_grid.text', { ns: 'projects' })}</Text>,
        confirmText: t('main_canvas.modals.delete_spacer_grid.confirm', { ns: 'projects' }),
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirm: () => {
          void (async () => {
            setIsLayerModifying(true)

            const token = await getAccessToken()
            if (!token) {
              return false
            }

            const deleteResult = await deleteShapes(token, project.project_id, shapesId, handleError)

            // track with mixpanel
            mixpanel.track('Delete saved/unsaved spacer annotation', {
              'Inspection area ID': project.project_id,
              'Number of deleted annotations (rhombi)': shapesId.rhombi.length,
              'Number of deleted annotations (plane)': shapesId.planes.length,
            })

            if (deleteResult) {
              const newSpacerAnnotations = spacerAnnotations.filter(
                (spacer) => !shapesId.planes.includes(spacer.shape_id) && !shapesId.rhombi.includes(spacer.shape_id)
              )
              dispatch(setSpacerAnnotations(newSpacerAnnotations))

              const newSelectedShapeIds = selectedShapeIds.filter(
                (id) => !shapesId.rhombi.includes(id) && !shapesId.planes.includes(id)
              )
              setSelectedShapeIds(newSelectedShapeIds)
            }

            setIsLayerModifying(false)
            return deleteResult
          })()
          return true
        },
      })

      return true
    },
    [t, project, showModal, selectedShapeIds, spacerAnnotations, getAccessToken, handleError, dispatch]
  )

  const deleteDistances = useCallback(
    (index: number) => {
      if (!project?.project_id) {
        return false
      }

      showModal({
        title: t('main_canvas.modals.delete_distance.title', { ns: 'projects' }),
        body: <Text>{t('main_canvas.modals.delete_distance.text', { ns: 'projects' })}</Text>,
        confirmText: t('main_canvas.modals.delete_distance.confirm', { ns: 'projects' }),
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirm: () => {
          void (async () => {
            setIsLayerModifying(true)

            // No need to call API if removing temporary distance
            let deleteResult = true
            if (distanceAnchors[index].id) {
              const token = await getAccessToken()
              if (!token) {
                return false
              }

              deleteResult = await deleteDistance(
                token,
                project.project_id,
                distanceAnchors[index].id || '',
                handleError
              )
            }

            // track with mixpanel
            mixpanel.track('Delete saved/unsaved distance object', {
              'Inspection area ID': project.project_id,
              'Number of deleted distances': 1,
            })

            const newDistances = [...distanceAnchors]
            if (deleteResult) {
              newDistances.splice(index, 1)
              setDistanceAnchors(newDistances)
            }

            setIsLayerModifying(false)
            return deleteResult
          })()
          return true
        },
      })

      return true
    },
    [t, distanceAnchors, getAccessToken, project, showModal, handleError]
  )

  //* 鉄筋canvas用
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })

  //* ローディング管理用
  const [initCompleted, setInitCompleted] = useState(false)

  //* Clean the anchor picking status
  //* Should be called before changing tool
  const resetState = useCallback(() => {
    const shapeKey = EDITOR_TOOL_KEYS[selectedTool]

    if (shapeKey) {
      const requiredAnchors = EDITOR_REQUIRED_ANCHORS[shapeKey]
      const anchorPoints = [...anchors[shapeKey]]
      const lastAnchor = anchorPoints.pop()

      if (processingAnchor || (lastAnchor?.points?.length && lastAnchor.points.length < requiredAnchors)) {
        setProcessingAnchor(undefined)
        setAnchors({ ...anchors, [shapeKey]: anchorPoints })
      }
    } else if (selectedTool === EDITOR_TOOLS.DISTANCE) {
      const requiredAnchors = 2
      const anchorPoints = [...distanceAnchors]
      const lastAnchor = anchorPoints.pop()

      if (processingAnchor || (lastAnchor?.points?.length && lastAnchor.points.length < requiredAnchors)) {
        setProcessingAnchor(undefined)
        setDistanceAnchors(anchorPoints)
      }
    }

    dispatch(setIsToolProcessing(false))
    setSelectedPoint(undefined)
    setIsDragging(false)
    setHoveredPoint(undefined)
    dispatch(resetCommentPopupPosition())
  }, [selectedTool, dispatch, anchors, processingAnchor, distanceAnchors])

  const finalizeChangingTool = useCallback((tool: string) => {
    setSelectedTool(tool)
    if (
      tool === EDITOR_TOOLS.CYLINDER_CUBOID ||
      tool === EDITOR_TOOLS.TORUS_CUBOID ||
      tool === EDITOR_TOOLS.COMMENT_CUBOID
    ) {
      setSelectedSubTool(DEFAULT_EDITOR_SUB_TOOL.CUBOID)
    }
  }, [])

  const changeTool = useCallback(
    (tool: string, skipClearAnchorFramesChecking?: boolean) => {
      if (selectedTool === tool) {
        return
      }

      if (isMovingComment && tool === EDITOR_TOOLS.COMMENT_CUBOID) {
        dispatch(setMovingCommentCartesianPosition(undefined))
      }
      if (isMovingComment && tool === EDITOR_TOOLS.COMMENT) {
        dispatch(resetCuboidStates())
      }

      resetState()

      // clear guidelines if any
      if (
        !skipClearAnchorFramesChecking &&
        needClearAnchorFrames(tool, cuboidAnchor, anchors, spacerAnnotationAnchors)
      ) {
        const textBody = (currentTool: string) => {
          switch (currentTool) {
            case EDITOR_TOOLS.CYLINDER:
            case EDITOR_TOOLS.CYLINDER_CUBOID:
              return t('main_canvas.modals.switch_tool.quit_detect_rebar', { ns: 'projects' })
            case EDITOR_TOOLS.TORUS:
            case EDITOR_TOOLS.TORUS_CUBOID:
              return t('main_canvas.modals.switch_tool.quit_detect_hoop', { ns: 'projects' })
            case EDITOR_TOOLS.PLANE:
            case EDITOR_TOOLS.PLANE_VIRTUAL:
              return t('main_canvas.modals.switch_tool.quit_detect_formwork', { ns: 'projects' })
            case EDITOR_TOOLS.COMMENT_CUBOID:
              return t('main_canvas.modals.switch_tool.quit_comment_cuboid', { ns: 'projects' })
            case EDITOR_TOOLS.PCD_TRIM_CUBOID:
              return t('main_canvas.modals.switch_tool.quit_trim_pcd', { ns: 'projects' })
            case EDITOR_TOOLS.SPACER_ANNOTATION:
              return t('main_canvas.modals.switch_tool.quit_spacer_grid', { ns: 'projects' })
            default:
              return t('main_canvas.modals.switch_tool.quit_default', { ns: 'projects' })
          }
        }

        showModal({
          body: textBody(selectedTool),
          confirmText: t('main_canvas.modals.switch_tool.confirm', { ns: 'projects' }),
          onConfirm: () => {
            setAnchors(INITIAL_SHAPE_STATE())
            dispatch(resetCuboidStates())
            dispatch(setSpacerAnnotationAnchors([]))

            finalizeChangingTool(tool)
            return true
          },
        })
      } else {
        finalizeChangingTool(tool)
      }
    },
    [
      t,
      selectedTool,
      isMovingComment,
      resetState,
      cuboidAnchor,
      anchors,
      spacerAnnotationAnchors,
      showModal,
      dispatch,
      finalizeChangingTool,
    ]
  )
  const changeSubTool = useCallback((subTool: string) => {
    setSelectedSubTool(subTool)
  }, [])
  const changeAutoDetectSituation = useCallback((situation: string) => {
    setAutoDetectSituation(situation)
  }, [])
  const changeHoveredPoint = useCallback((point?: FocusedPoint) => {
    setHoveredPoint(point)
  }, [])
  const changeSelectedPoint = useCallback((point?: FocusedPoint) => {
    setSelectedPoint(point)
  }, [])
  const changeIsAllActionsDisabled = useCallback((disabled: boolean) => {
    setIsAllActionsDisabled(disabled)
  }, [])
  const changeIsJobRunning = useCallback(
    (running: boolean) => {
      dispatch(setIsJobRunning(running))
      if (running && selectedTool !== EDITOR_TOOLS.DISTANCE) {
        changeTool(EDITOR_TOOLS.MOVE, true)
      }
    },
    [dispatch, selectedTool, changeTool]
  )
  const changeIsDragging = useCallback((dragging: boolean) => {
    setIsDragging(dragging)
  }, [])
  const changeIsMouseDown = useCallback((isDown: boolean) => {
    setIsMouseDown(isDown)
  }, [])
  const changeHoveredShapeId = useCallback((shapeId: string) => {
    setHoverShapeId(shapeId)
  }, [])
  const changeProcessingAnchor = useCallback((anchor?: PointArray) => {
    setProcessingAnchor(anchor)
  }, [])
  const changeSelectedShapeIds = useCallback((shapeIds: string[]) => {
    setSelectedShapeIds(shapeIds)
  }, [])
  const changeSelectedInspectionItem = useCallback((item?: InspectionItem) => {
    setSelectedInspectionItem(item)
  }, [])
  const changeSelectedSpacerInspectionItem = useCallback((item?: SpacerInspectionItem) => {
    setSelectedSpacerInspectionItem(item)
  }, [])
  const changeSelectedIFCGeometryIndex = useCallback((fileIndex?: number, index?: number) => {
    setSelectedIFCGeometryIndex(fileIndex !== undefined && index !== undefined ? { fileIndex, index } : undefined)
  }, [])
  const changeMaskRegions = useCallback((regions: CuboidForMask[]) => {
    setMaskRegions(regions)
  }, [])
  const changeMaskRegionsOutsideVisible = useCallback((visible: boolean) => {
    setMaskRegionsOutsideVisible(visible)
  }, [])
  const changeIFCFiles = useCallback((files: IFCFile[]) => {
    setIFCFiles(files)
  }, [])
  const updateSelectedShapeIds = useCallback((shapeIds: string[]) => {
    setSelectedShapeIds(shapeIds)
  }, [])

  const updateShapesDistancesVisibility = useCallback(
    (visible: boolean) => {
      setShapesDistancesVisible(visible)

      // track with mixpanel
      mixpanel.track('Change visibility of inter-rebar distances', {
        'Inspection area ID': project_id,
        'Visibility (new)': visible,
        'Visibility (old)': !visible,
      })
    },
    [project_id]
  )
  const updateCanvasRenderers = useCallback((renderes: { gl: WebGLRenderer; scene: Scene; camera: Camera }) => {
    setCanvasRenderers(renderes)
  }, [])
  const contextValue = useMemo(
    () => ({
      addDistanceAnchor,
      autoDetectSituation,
      canvasRenderers,
      changeAutoDetectSituation,
      changeHoveredPoint,
      changeHoveredShapeId,
      changeIFCFiles,
      changeIsAllActionsDisabled,
      changeIsJobRunning,
      changeIsDragging,
      changeIsMouseDown,
      changeMaskRegions,
      changeMaskRegionsOutsideVisible,
      changeProcessingAnchor,
      changeSelectedIFCGeometryIndex,
      changeSelectedInspectionItem,
      changeSelectedSpacerInspectionItem,
      changeSelectedPoint,
      changeSelectedShapeIds,
      changeTool,
      changeSubTool,
      deleteOverlaps: deleteSelectedOverlaps,
      deleteShapes: deleteSelectedShapes,
      deleteSpacerAnnotations: deleteSelectedSpacerAnnotations,
      deleteDistances,
      distanceAnchors,
      hoveredPoint,
      hoveredShapeId,
      IFCFiles,
      IFCGeometries,
      isAllActionsDisabled,
      isDragging,
      isMouseDown,
      isLayerModifying,
      maskRegions,
      maskRegionsOutsideVisible,
      overlaps,
      processingAnchor,
      project,
      selectedIFCGeometryIndex,
      selectedInspectionItem,
      selectedSpacerInspectionItem,
      selectedPoint,
      selectedShapeIds,
      selectedTool,
      selectedSubTool,
      anchors,
      shapeGroups,
      shapes,
      shapesDistances,
      shapesDistancesVisible,
      shapesPreviewDistances,
      updateAnchorPoint,
      updateAllAnchorsStatus,
      updateAllDistanceAnchorsStatus,
      updateAllSelectedShapesStatus,
      updateAllOverlapsStatus,
      updateAllShapesStatus,
      updateAllSpacerAnnotationsStatus,
      updateAnchorStatus,
      updateCanvasRenderers,
      updateDistanceAnchorPoint,
      updateDistanceAnchorStatus,
      updateOverlapStatus,
      updateSpacerAnnotationStatus,
      updateSelectedPointDiameter,
      updateSelectedPointValue,
      updateSelectedShapeIds,
      updateShapeGroups,
      updateShapeGroupStatus,
      updateShapeStatus,
      updateShapesDistancesVisibility,
      updateSpacerAnchorStatus,
      updateSpacerAnnotationAnchorPoint,
    }),
    [
      addDistanceAnchor,
      autoDetectSituation,
      canvasRenderers,
      changeAutoDetectSituation,
      changeHoveredPoint,
      changeHoveredShapeId,
      changeIFCFiles,
      changeIsAllActionsDisabled,
      changeIsJobRunning,
      changeIsDragging,
      changeIsMouseDown,
      changeMaskRegions,
      changeMaskRegionsOutsideVisible,
      changeProcessingAnchor,
      changeSelectedIFCGeometryIndex,
      changeSelectedInspectionItem,
      changeSelectedSpacerInspectionItem,
      changeSelectedPoint,
      changeSelectedShapeIds,
      changeTool,
      changeSubTool,
      deleteSelectedOverlaps,
      deleteSelectedShapes,
      deleteSelectedSpacerAnnotations,
      deleteDistances,
      distanceAnchors,
      hoveredPoint,
      hoveredShapeId,
      IFCFiles,
      IFCGeometries,
      isAllActionsDisabled,
      isDragging,
      isMouseDown,
      isLayerModifying,
      maskRegions,
      maskRegionsOutsideVisible,
      overlaps,
      processingAnchor,
      project,
      selectedIFCGeometryIndex,
      selectedInspectionItem,
      selectedSpacerInspectionItem,
      selectedPoint,
      selectedShapeIds,
      selectedTool,
      selectedSubTool,
      anchors,
      shapeGroups,
      shapes,
      shapesDistances,
      shapesDistancesVisible,
      shapesPreviewDistances,
      updateAnchorPoint,
      updateAllAnchorsStatus,
      updateAllDistanceAnchorsStatus,
      updateAllSelectedShapesStatus,
      updateAllOverlapsStatus,
      updateAllShapesStatus,
      updateAllSpacerAnnotationsStatus,
      updateAnchorStatus,
      updateCanvasRenderers,
      updateDistanceAnchorPoint,
      updateDistanceAnchorStatus,
      updateOverlapStatus,
      updateSpacerAnnotationStatus,
      updateSelectedPointDiameter,
      updateSelectedPointValue,
      updateSelectedShapeIds,
      updateShapeGroups,
      updateShapeGroupStatus,
      updateShapeStatus,
      updateShapesDistancesVisibility,
      updateSpacerAnchorStatus,
      updateSpacerAnnotationAnchorPoint,
    ]
  )

  useEffect(() => {
    if (cookies) {
      // update the cookie expiration date
      setCookie(EDITOR_BACKGROUND_COOKIE_NAME, cookies[EDITOR_BACKGROUND_COOKIE_NAME] || EDITOR_DEFAULT_BACKGROUND, {
        expires: COOKIE_EXPIRE,
      })
    }
  }, [cookies, setCookie])

  useEffect(() => {
    if (!baseDiameter) {
      return
    }

    setAnchors({
      cylinders: anchors.cylinders.map((anchor) => ({ ...anchor, diameter: anchor.diameter || baseDiameter })),
      tori: anchors.tori.map((anchor) => ({ ...anchor, diameter: anchor.diameter || baseDiameter })),
      planes: anchors.planes.map((anchor) => ({ ...anchor, diameter: anchor.diameter || baseDiameter })),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseDiameter])

  useEffect(() => {
    if (cuboid) {
      if (
        selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID &&
        autoDetectSituation !== EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC
      ) {
        setAutoDetectSituation(EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS)
      }
      if (selectedTool === EDITOR_TOOLS.TORUS_CUBOID) {
        setAutoDetectSituation(EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS)
      }
    } else {
      setAutoDetectSituation('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuboid, selectedTool])

  useDebouncedEffect(
    () => {
      const handleResize = () => {
        const canvasBox = document.getElementById('canvasBox') as HTMLElement
        setWindowSize({
          width: window.innerWidth,
          height: canvasBox.clientHeight,
        })
      }

      void (async () => {
        if (!initCompleted && userLoaded && project_id) {
          if (!project) {
            const token = await getAccessToken()
            if (!token) {
              return false
            }

            //* DBからprojectとcylinders取得
            const [requestedProject, requestedShapes, requestedOverlaps, requestedMaskRegions, requestedDistances] =
              await Promise.all([
                getProject(token, project_id, handleError),
                getShapes(token, project_id, handleError),
                getOverlaps(token, project_id, handleError),
                getMaskingRegions(token, project_id, handleError),
                getDistances(token, project_id, handleError),
              ])

            // Extract virtual shapes from shapes
            const virtualRhombi = requestedShapes?.rhombi || []
            const virtualPlanes = requestedShapes?.planes.filter((p) => p.is_virtual) || []

            setProject(requestedProject || undefined)
            setShapes({
              tori: requestedShapes?.tori || [],
              cylinders: requestedShapes?.cylinders || [],
              planes: requestedShapes?.planes.filter((p) => !p.is_virtual) || [],
            })
            setOverlaps(requestedOverlaps || [])
            setMaskRegions(requestedMaskRegions || [])
            dispatch(
              setSpacerAnnotations([
                ...virtualRhombi.map((r: Rhombus) => parseRhombus(r)),
                ...virtualPlanes.map((p: Plane) => parsePlane(p)),
              ])
            )
            setDistanceAnchors(requestedDistances || [])

            return setInitCompleted(true)
          }
        }
        return false
      })()

      //* canvasがリサイズされるたびに再計算
      window.addEventListener('resize', handleResize)
      handleResize()

      return () => {
        window.removeEventListener('resize', handleResize)
        setInitCompleted(true)
      }
    },
    [initCompleted, userLoaded, project_id, getAccessToken, project, handleError],
    100
  )

  // Prevent iPad from pulling to refresh
  useEffect(() => {
    document.body.classList.add('no-overflow')
    return () => {
      document.body.classList.remove('no-overflow')
    }
  }, [])

  // Reset the store when leaving the page
  useEffect(
    () => () => {
      // TODO: can we rewrite as a single action to reset all the stores at once?
      dispatch(resetEditorStore())
      dispatch(resetCuboidStore())
      dispatch(resetCommentStore())
      dispatch(resetAnnotationStore())
    },
    [dispatch]
  )

  const isUpdatingShapeGroup = shapeGroups.some((group) => group.selected)

  return (
    <EditorContext.Provider value={contextValue}>
      <AppTitle project={project} projectGroup={projectGroup} />
      <Box w="100svw" h="100svh" className="editor">
        <Flex justify="space-between" align="start" h="100%">
          <Box id="canvasBox" flex={1} h="100%">
            <MainCanvas
              addCuboidAnchor={addCuboidAnchor}
              addAnchor={addAnchor}
              addSpacerAnnotationAnchor={addSpacerAnnotationAnchor}
              updateAnchorPoint={updateAnchorPoint}
              updateDistanceAnchorPoint={updateDistanceAnchorPoint}
              updateSpacerAnnotationAnchorPoint={updateSpacerAnnotationAnchorPoint}
              windowSize={windowSize}
              setMovingPrefixedPosition={setMovingPrefixedPosition}
              movingPrefixedPosition={movingPrefixedPosition}
              user={user}
              setIFCFiles={setIFCFiles}
              setIFCGeometries={setIFCGeometries}
              cameraFocusPoint={cameraFocusPoint}
              setCameraFocusPoint={setCameraFocusPoint}
            />
          </Box>
          {!isAllActionsDisabled && !shapesPreviewDistances && (
            <Toolbar isAllowedModify={isAllowedModify} isAllowedView={isAllowedView} />
          )}
          {!isJobRunning &&
            (selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID ||
              selectedTool === EDITOR_TOOLS.TORUS_CUBOID ||
              selectedTool === EDITOR_TOOLS.COMMENT_CUBOID ||
              selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID) &&
            cuboid && <SubToolbar moveToPrefixedPosition={setMovingPrefixedPosition} />}
          {!isAllActionsDisabled && !shapesPreviewDistances && <InstructionBar />}
          <AssistantBar
            move={setMovingPrefixedPosition}
            fixedPosition
            removeCameraFocusPoint={
              cameraFocusPoint
                ? () => {
                    setCameraFocusPoint(undefined)

                    // track with mixpanel
                    mixpanel.track('Remove camera rotation center point', {
                      'Inspection area ID': project_id,
                    })
                  }
                : undefined
            }
          />
          {/* ((action panel's bottom position = 4) * 2  = 8) * 4px */}
          <InfoPanels
            actionPanelHeight={(actionPanelRef.current?.clientHeight || 0) + 8 * 4}
            isAllActionsDisabled={isAllActionsDisabled || !!shapesPreviewDistances}
            isAllowedModify={isAllowedModify}
          />
          <TopNav type={NAV_TYPES.EDITOR} canModify={isAllowedModify} />
          <VStack
            ref={actionPanelRef}
            minWidth={EDITOR_ACTION_BUTTON_MIN_WIDTH}
            maxWidth={EDITOR_ACTION_BUTTON_MAX_WIDTH}
            position="absolute"
            right={4}
            bottom={4}
          >
            {project && (shapes || spacerAnnotations) && !isUpdatingShapeGroup && (
              <InspectionButton
                setShapesPreviewDistances={setShapesPreviewDistances}
                setShapesDistances={(distances) =>
                  setShapesDistances({
                    // no need to store the corresponding shapes
                    // distances will be recalculated when any shape in inspection item is deleted
                    shapes: INITIAL_SHAPE_STATE(),
                    situation: '',
                    distances,
                  })
                }
                isAllowedModify={isAllowedModify}
                isAllowedView={isAllowedView}
              />
            )}
            {!isAllActionsDisabled && isUpdatingShapeGroup && <ShapeGroupRepickButton />}
            {!isAllActionsDisabled && project && shapes && selectedCylinders.length === 2 && !isUpdatingShapeGroup && (
              <ComputeOverlapButton setOverlaps={setOverlaps} />
            )}
            {!isAllActionsDisabled && !isUpdatingShapeGroup && (
              <>
                <ConvertButton setShapes={setShapes} setShapesDistances={setShapesDistances} setAnchors={setAnchors} />
                <ConvertVirtualPlaneButton setShapes={setShapes} setAnchors={setAnchors} />
                <ConvertSpacerAnnotationButton />
              </>
            )}
            {!isAllActionsDisabled &&
              selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID &&
              cuboid &&
              !isUpdatingShapeGroup && <SaveMaskRegionButton />}
            {!isAllActionsDisabled && !shapesPreviewDistances && !isUpdatingShapeGroup && (
              <>
                <SaveButton
                  setShapes={setShapes}
                  setOverlaps={setOverlaps}
                  resetShapesDistances={() =>
                    setShapesDistances({
                      shapes: INITIAL_SHAPE_STATE(),
                      distances: INITIAL_SHAPE_STATE(),
                      situation: '',
                    })
                  }
                  setIsLayerModifying={setIsLayerModifying}
                />
                <SaveDistanceButton setDistances={setDistanceAnchors} setIsLayerModifying={setIsLayerModifying} />
                <CADExportButton />
              </>
            )}
          </VStack>
        </Flex>
      </Box>
    </EditorContext.Provider>
  )
}

export default Editor
